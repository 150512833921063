import React, { Component } from "react";
import MapContainer from './GMap';

export class Map extends Component {
constructor(){
  super();
    this.state = {
      name: "",
      lng: 28.0506,
      lat: -26.1074,
      locations: [],
      allInfo: {},
      loading: true
    }
}

componentDidMount(){
  this.setState({
    ...this.state,
    name: this.props.match.params.id
  });

  if(this.props.match.params.id == "Davinci-Day-Spa-Sandton")
  {
    this.setState({
      name: "Davinci-Day-Spa-Sandton",
      lng: 28.0506,
      lat: -26.1074,
      locations: [],
      allInfo: {}
    });
  }

  if(this.props.match.params.id == "Kwamaritane-Spa-Pilanesberg")
  {
    this.setState({
      name: "Kwamaritane-Spa-Pilanesberg",
      lng: 27.1447,
      lat: -25.3343,
      locations: [],
      allInfo: {}
    });
  }

  if(this.props.match.params.id == "Bakubung-Day-Spa-Rustenburg")
  {
    this.setState({
      name: "Bakubung-Day-Spa-Rustenburg",
      lng: 27.0523,
      lat: -25.3463,
      locations: [],
      allInfo: {}
    });
  }

  if(this.props.match.params.id == "Leonardo-Spa-Sandton")
  {
    this.setState({
      name: "Leonardo-Spa-Sandton",
      lng: 28.0557,
      lat: -26.1045,
      locations: [],
      allInfo: {}
    });
  }

  fetch(`https://legacybalance.co.za/api/spas/`)
  .then(response => response.json())
  .then(data => {
    return data;
  })
  .then(data => {
    let locs = [];

    const getLocs = (item, index) => {
      locs.push({latitude: item.latitude, longitude: item.longitude});
      if(item.name === this.props.match.params.id){        
        this.setState({
          ...this.state,
          lat: item.latitude,
          lng: item.longitude,
          allInfo: data
        });
      }
      else{
        console.log("MAP_COORDS: ", item);
      }
    }

    data.forEach(getLocs);
    this.setState({
      ...this.state,
      locations: locs
    })
  }).
  then(() => this.setState({...this.state, loading: false}));
}

    render() {
      {console.log("CURRENT_COORDS_LAT: ", this.state.lat)}
      {console.log("CURRENT_COORDS_LNG: ", this.state.lng)}
      let initialCenter = { lat: this.state.lat, lng: this.state.lng};
      {console.log("CURRENT_COORDS_INITIAL:", initialCenter)}
      return (
        <React.Fragment>
              <div>
                <div className="map-wrapper">
                  {(this.state.locations.length > 0)?<MapContainer 
                  initialCenter={initialCenter}
                  locs={this.state.locations}
                  name={this.state.name}
                  allInfo={this.state.allInfo}
                  />:<React.Fragment></React.Fragment>}
                  
                </div>
              </div>         
        </React.Fragment>
      );
    }
  }