import React, { Component } from "react";

class Cancellation extends Component {

    render(){
        return(
            <React.Fragment>
                <h1>Purchase has been cancelled</h1>
            </React.Fragment>
        );
    };

}

export default Cancellation;