import React, {Component} from "react";
import "./SpaCard.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import ProductSliderMap from "../ProductSlider/ProductSliderMap";
import OperationHours from "../OperationHours/OperationHours";

class InfoWindowContents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      allInfo: []
    };

    this.toggle = this.toggle.bind(this);
  }
  
  componentDidMount() {
    this.setState({
      ...this.state,
      allInfo: this.props.allInfo
    })
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  lightOrDark(){
    if(this.props.cssLight){
      return "light";
    }else{
      return "dark";
    }
  }

  render(){
    return(
      <React.Fragment>
          <div className="spa-info-card">
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  <div className="spa-map-info">
                  <div
                style={{
                  backgroundImage:
                    `url(\"${this.props.coverUrl}\")`
                      }}
                      className="spa-card__image"
                    >
                      <div className={`spa-card__logo-wrapper--${this.lightOrDark()}`}>
                        <img
                          src={this.props.logoUrl}
                          alt=""
                        />
                      </div>
                    </div>         
                    <div className="spa-info--info">
                      <div className="row">
                        <div className="col-12">
                          <div className="spa-info__btn--location">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="map-marker-alt"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              className="svg-inline--fa fa-map-marker-alt fa-w-12 fa-9x"
                            >
                              <path
                                fill="currentColor"
                                d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                className=""
                              ></path>
                            </svg>{" "}
                            {this.props.address}
                          </div>
                        </div>
                        <div className="col-4 col-sm-4">
                          <a href={`tel:${this.props.phoneNumber}`}>
                            <div className="spa-info__btn">
                              <div className="spa-info__btn--icon">
                              <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="map-marker-alt"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              className=""
                            >
                                  <path fill="currentColor" d="M484.25 330l-101.59-43.55a45.86 45.86 0 0 0-53.39 13.1l-32.7 40a311.08 311.08 0 0 1-124.19-124.12l40-32.7a45.91 45.91 0 0 0 13.1-53.42L182 27.79a45.63 45.63 0 0 0-52.31-26.61L35.5 22.89A45.59 45.59 0 0 0 0 67.5C0 313.76 199.68 512.1 444.56 512a45.58 45.58 0 0 0 44.59-35.51l21.7-94.22a45.75 45.75 0 0 0-26.6-52.27zm-41.59 134.09C225.08 463.09 49 287 48 69.3l90.69-20.9 42.09 98.22-68.09 55.71c46.39 99 98.19 150.63 197 197l55.69-68.11 98.19 42.11z"
                                    className=""
                                  ></path>
                                </svg>
                              </div>
                              
                            </div>
                          </a>
                        </div>
                        <div className="col-4 col-sm-4">
                          <a href={`tel:${this.props.cellNumber}`}>
                            <div className="spa-info__btn">
                              <div className="spa-info__btn--icon">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="mobile-android-alt"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                  className="svg-inline--fa fa-mobile-android-alt fa-w-10 fa-9x"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-64 452c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v8zm64-80c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"
                                    className=""
                                  ></path>
                                </svg>
                              </div>
                             
                            </div>
                          </a>
                        </div>
                        <div className="col-4 col-sm-4">
                          <a href={`mailto:${this.props.emailAddress}`}>
                            <div className="spa-info__btn">
                              <div className="spa-info__btn--icon">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="far"
                                  data-icon="envelope"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  className="svg-inline--fa fa-envelope fa-w-16 fa-7x"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                                    className=""
                                  ></path>
                                </svg>
                              </div>
                             
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          {/* {console.log("MODAL: ", this.props.allSpaInfo)} */}
          <ModalHeader toggle={this.toggle}>{this.props.allSpaInfo.name} - Operation Hours</ModalHeader>
          <ModalBody>
          <div className="modal-body">
              <div className="open-hours">
                <div className="open-hours__days">Monday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.monday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeMonday).getHours()} PM</div>
                <div className="open-hours__days">Tuesday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.tuesday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeTuesday).getHours()} PM</div>
                <div className="open-hours__days">Wednesday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.wednesday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeWednesday).getHours()} PM</div>
                <div className="open-hours__days">Thursday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.thursday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeThursday).getHours()} PM</div>
                <div className="open-hours__days">Friday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.friday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeFriday).getHours()} PM</div>
                <div className="open-hours__days">Saturday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.saturday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeSaturday).getHours()} PM</div>
                <div className="open-hours__days">Sunday</div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.sunday).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimeSunday).getHours()} PM</div>
                <div className="open-hours__days">
                  Public Holidays
                </div>
                <div className="open-hours__times">{new Date(this.props.allSpaInfo.publicHolidays).getHours()} AM - {new Date(this.props.allSpaInfo.closingTimePublicHolidays).getHours()} PM</div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
             Call Now
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
 </div>
    
          
      </React.Fragment>
      );
  }
};

export default InfoWindowContents;
