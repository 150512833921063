import React from "react";

const SpaRow = props => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-2">
          <p>{props.Name}</p>
        </div>
        <div className="col-8">
          <p>{props.email}</p>
        </div>
     
          <div className="col-1 text-right">
            <button className="btn btn-danger"
            onClick={() => props.deleteMethod(props.id)}>Delete</button>
          </div>
          <div className="col-1 text-right">
            <button
              className="btn btn-primary"
              onClick={() => props.editMethod(props.idIndex)}
            >
              Edit
            </button>
          </div>
       
      </div>
      <br />
    </React.Fragment>
  );
};

export default SpaRow;
