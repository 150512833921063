import React from "react";
import "./Packages.css";
import FormContact from "../../FormContact/FormContact";
    
const Packages = (props) => {
return(
<React.Fragment>
    <div class="spa-packages">
    <h2>Group Bookings &amp; Packages</h2>
    <p>Please contact us for any group booking requests or if you have special requirements. We can tailor custom bookings dependant on your groups needs.</p>
    <FormContact FormType="Packages" spaName={props.TypeOfContact}/>
    </div>
</React.Fragment>
);
}
    
export default Packages;