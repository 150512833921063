import React from "react";
import "./MapBanner.css";

const MapBanner = props => {
  return <React.Fragment>
    {/* <div className="container-fluid">
      <h1>Map Banner</h1>
    </div> */}
  </React.Fragment>;
};

export default MapBanner;
