import React from "react";
import "./FeaturedArea.css";

const FeaturedArea = (props) => {
    return(
        <React.Fragment>
            {/* <div className="home-slider__wrapper">
                <div className="home-slider__banner">
                    Test
                </div>
            </div> */}
        </React.Fragment>
    );
}

export default FeaturedArea;