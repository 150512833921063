import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withFormik } from "formik";
// import ImgDropAndCrop from "../Media/Crop";
import MyEditor from "./MyEditor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaListActions from "../../../../actions/spaListActions";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from "draft-js";
import RichEditorExample from "./RichEditor";
// import draftToHtml from "draftjs-to-html";
// import PdfPreviewRefresh from "../Media/PdfPreviewRefresh";
// import MyDropZone from "../Media/ImageDrop";

class PromotionAdmin extends React.Component {
  constructor(props) {
    super(props);

    let editorStateFromHtml = "";
    let blocksFromHTML = "";

    blocksFromHTML = convertFromHTML("<p>T's and C's</p>");
    editorStateFromHtml = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.state = {
      data: [
        {
          endDate: "",
          isArchived: false,
          longDescription:
            "The first promotion at Spa 3, where you won't have to sacrifice quality for savings.",
          name: "Spa 3 First Promotion",
          price: 500,
          promotionId: 1,
          shortDescription: "The first promotion at Spa 3.",
          startDate: "2020-10-25T10:01:14.167984",
          termsAndConditions: "<p>Some T's and C's</p>",
          validSpasList: "Spa 3, Spa 3",
          pdfUrl: "https://arxiv.org/pdf/quant-ph/0410100.pdf",
          imageUrl: "https://legacybalance.co.za/promotion/no-image-found.jpg"
        }
      ],
      editorState: editorStateFromHtml
    };
  }

  updateFile = newUrl => {
    fetch(`https://legacybalance.co.za/api/spas/${this.props.name}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          ...this.state,
          data: data[0].promotion
        });
        console.log("PROMOTION_TEST: ", data[0].promotion);
      });
    // console.log({...this.state, data: [{...this.state.data, pdfUrl: newUrl}]});
    // this.setState({...this.state, data: {...this.state.data[0], pdfUrl: newUrl}});
    // console.log("NEW_URL: ", this.state.data);
  };

  componentDidMount() {

    console.log("THE_PROP: ", this.props.Id);

    fetch(`https://legacybalance.co.za/api/spas/${this.props.name}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          ...this.state,
          data: data[0].promotion,
          
        });
        console.log("PROMOTION_TEST: ", data[0].promotion);
      });

    let pdf = "https://arxiv.org/pdf/quant-ph/0410100.pdf";
    const pdfExists = fetch(this.props.TreatmentMenuUrl).then(response => {
      response.body
        .getReader()
        .read()
        .then(data => data.value.length)
        .then(data => {
          console.log(data);
          if (data < 3000) {
            pdf = "https://arxiv.org/pdf/quant-ph/0410100.pdf";
            this.setState({
              ...this.state,
              pdfUrl: pdf
            });
          } else {
            pdf = this.props.TreatmentMenuUrl;
            this.setState({
              ...this.state,
              pdfUrl: pdf
            });
          }
        });
    });
  }

  handleSubmit = (values, { setSubmitting }) => {
    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    //   setSubmitting(false);
    // }, 1000);
    
    console.log("TO_SEND: ", values);
    // let jsonData = JSON.parse(JSON.stringify(values));
    // jsonData.push({termsAndConditions: this.state.data[0].termsAndConditions});
    values.termsAndConditions = this.props.listOfSpas.promotion.termsAndConditions;
    console.log("TO_SEND: ", values);
    fetch(`https://legacybalance.co.za/api/spas/promotioninfo/${this.props.Id}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    });
    setSubmitting(false);
  };

  handleRichEditorEdit = (NewEditorState, id) => {

    this.props.spaListActions.setEditor(NewEditorState, id);

    // const content = NewEditorState.getCurrentContent();
    // const rawContentState = convertToRaw(content);
    // const markup = draftToHtml(rawContentState);

    // this.setState({
    //   ...this.state,
    //   data: [
    //     {
    //       ...this.state.data[0],
    //       termsAndConditions: markup
    //     }
    //   ]
    // });

    // console.log("CURRENT_CONTENT_OF_EDITOR: ", this.state.data[0].termsAndConditions);
  };

  spinner = () => {
    return (
      <div
        className="spinner-border"
        role="status"
        style={{ marginLeft: "15px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  onChange = date => {
    this.setState({ ...this.state, date });
    alert(date);
  };

  render() {
    console.log(this.state.data);
    const initialVals = {
      endDate: this.props.listOfSpas.promotion.endDate.slice(0, -3),//this.state.data[0].startDate.slice(0, -3),//this.state.data[0].endDate.slice(0, -3),
      dateFrom:  this.props.listOfSpas.promotion.startDate.slice(0, -3),//this.state.data[0].startDate.slice(0, -3),
      name: this.props.listOfSpas.promotion.name,//this.state.data[0].name,
      shortDescription:this.props.listOfSpas.promotion.shortDescription,// this.state.data[0].shortDescription,
      longDescription: this.props.listOfSpas.promotion.longDescription,//this.state.data[0].longDescription,
      price: this.props.listOfSpas.promotion.price,//this.state.data[0].price
    };
    console.log(initialVals);
    return (
      <React.Fragment>
        <h1>Promotion Admin</h1>
        <Formik
          enableReinitialize
          initialValues={initialVals}
          validationSchema={Yup.object().shape({
            dateFrom: Yup.string().required("Date From is required"),
            endDate: Yup.string().required("Date to is required"),
            name: Yup.string().required("Name is required"),
            shortDescription: Yup.string().required(
              "Short description is required"
            ),
            longDescription: Yup.string().required(
              "Long description is required"
            ),
            price: Yup.number().required("The price is required"),
            //validSpasList: Yup.array().required("The valid spas are required")
          })}
          onSubmit={this.handleSubmit}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleBlur,
            validateForm,
          }) => (
            <Form>              
              <div className="form-group">
                <label htmlFor="dateFrom">Promotion Name</label>
                <Field
                  name="name"
                  type="text"
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="price">Price From</label>
                <Field
                  name="price"
                  type="number"
                  className={
                    "form-control" +
                    (errors.price && touched.price ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="price"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="dateFrom">From Date</label>
                <Field
                  name="dateFrom"
                  type="datetime-local"
                  className={
                    "form-control" +
                    (errors.dateFrom && touched.dateFrom ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="dateFrom"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <Field
                  name="endDate"
                  type="datetime-local"
                  className={
                    "form-control" +
                    (errors.endDate && touched.endDate ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="endDate"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="shortDescription">Short description</label>
                <Field
                  name="shortDescription"
                  type="textarea"
                  className={
                    "form-control" +
                    (errors.shortDescription && touched.shortDescription
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="shortDescription"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="longDescription">Long description</label>
                <Field
                  name="longDescription"
                  type="textarea"
                  className={
                    "form-control" +
                    (errors.longDescription && touched.longDescription
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="longDescription"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label>Terms and Conditions</label>
                <RichEditorExample
                  initialState={this.state.editorState}
                  handleEdit={this.handleRichEditorEdit.bind(this)}
                  id={this.props.selected}
                />
              </div>
              <div className="form-group">
                <label>Promotion Image</label>
                {/* <ImgDropAndCrop
                  ID={this.props.Id}
                  method="PromotionImage"
                  // LogoUrl={this.state.data[0].imageUrl}
                  LogoUrl="https://legacybalance.co.za/"
                  isPdf={false}
                  ratio={0.67}
                /> */}
              </div>
              <div className="form-group">
                <label>Promotion PDF</label>
                {/* {console.log(this.state.data[0].pdfUrl)} */}
                {/* <PdfPreviewRefresh url={this.state.data[0].pdfUrl} />
                <MyDropZone
                  method="PromotionPDF"
                  ID={this.props.Id}
                  url={this.props.URL}
                  src={this.state.src}
                  crop={this.state.crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onCropChange={this.onCropChange}
                  croppedImageUrl={this.state.croppedImageUrl}
                  DocUrl={this.props.TreatmentMenuUrl}
                  updateFile={this.updateFile}
                /> */}
              </div>
              <br/>
              <div className="form-group">
                <div className="row">
                  <div className="col text-left">
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.props.back()}
                    >
                      Back to list
                    </button>
                  </div>
                  <div className="text-right">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2"
                      disabled={isSubmitting}
                      onClick={() => validateForm().then(() => console.log("Valid"))}
                    >
                      Submit
                      {isSubmitting ? this.spinner() : ""}
                    </button>
                    <button type="reset" className="btn btn-secondary">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </React.Fragment>
    );
  }
}

//export default PromotionAdmin;


function mapStateToProps(state) {
  console.log("SPALIST_FOR_NULL_CHECK: ", state.spaListReducer.spaList);
  console.log("SPALIST_FOR_NULL_CHECK: ", state.spaListReducer);
  return {
    listOfSpas: state.spaListReducer.spaList[state.spaListReducer.selectedSpaId],
    selected: state.spaListReducer.selectedSpaId
  };
}

function mapDispatchToProps(dispatch) {
  console.log(spaListActions);
  console.log(dispatch);
  return {
    spaListActions: bindActionCreators(spaListActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionAdmin);