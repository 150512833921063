import React, { Component } from "react";

import Slider from "../../components/Slider/Slider";
import FeaturedArea from "../../components/FeaturedArea/FeaturedArea";
import SpaCard from "../../components/SpaCard/SpaCard";
// import SpaCard from "../../components/Spas/AvailableSpas";
import VoucherBanner from "../../components/VoucherBanner/VoucherBanner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homeAction from "../../actions/homeAction";
import "./Home.css";
import Spa from "../SpaPage/Spa";

class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      hasLoaded: false,
      spaId: 0,
      spaChosen: false,
      spaIndex: 0
    };
  }

  componentDidMount() {
    this.props.spaListActionsStuff.getInitialValsSpaList();
    // console.log("ComponentDidMount()", this.props);
    this.setState({...this.state, hasLoaded: true});
  }

  isEven(n) {
    return n % 2 == 0;
 }

 selectSpa = (i) => {
   this.setState({...this.state, spaChosen: true, spaIndex: i});
 }

hasFetched(){
  if(this.props.spasAvailable.HomeReducer.spaList.length > 1 && !this.state.spaChosen){
    return (
      <React.Fragment >
          {this.props.spasAvailable.HomeReducer.spaList.map((item, index) => {
            return(
              <React.Fragment key={item.spaId}>          
                <SpaCard  
                  key={item.spaId}
                  logoUrl={item.logo.url}
                  coverUrl={item.coverImage.url}
                  address={item.physicalAddress}
                  telephone={item.telephoneNumber}
                  cellNumber={item.cellNumber}
                  emailAddress={item.receptionEmailAddress}
                  cssLight={item.cssLight}
                  MoreInfo={this.selectSpa}
                  Index={index}
                  spaName={item.name}
                  allSpaInfo={item}
                  />
              </React.Fragment>
            );
         
          })}

          </React.Fragment>
        
    );
  }else if(this.state.spaChosen){
    return(
      <React.Fragment>
        <Spa
          Index={this.state.spaIndex}
          spaInformation={this.props.spasAvailable.HomeReducer.spaList}
          allInformation={this.props.spasAvailable}
        />
      </React.Fragment>
    );
  }
}

  render() {
    return (
      <React.Fragment>
        {/* <Slider/> */}        
        {/* <div key={'featured-promo-image-one'} className="temp-promo">
          <img src="https://legacybalance.co.za/FeaturedImages/March-2022--DaVinci-Digital-Promotinal-Artwork.jpg" alt="March 2023 Promotion" width={'100%'} className="mx-auto d-block"/>
        </div> */}
        <FeaturedArea />
        <div className="container-fluid home-layout">
        <div className="row">
       {/* <h1>{this.props.match.params.id}</h1> */}
        {this.hasFetched()}
        </div>
        </div>
        
        <VoucherBanner /> 
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  // console.log("HOME_STATE: ", state);
  return {
    spasAvailable: state
  };
};

function mapDispatchToProps(dispatch) {
  // console.log("HOME_ACTIONS: ", homeAction);
  // console.log(dispatch);
  return {
    spaListActionsStuff: bindActionCreators(homeAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
