import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as spaListActions from "../../actions/spaListActions";
import SpaList from "../../components/Spas/Admin/SpaList";
//import "../GeneralAdmin/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import SpaRow from "../../components/Spas/Admin/SpaRow/SpaRow";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

class Spas extends Component {
  static displayName = Spas.name;

  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      createNew: false,
      editSpa: false,
      spaId: 0
    };
  }

  spaSelected = () => {
    if(this.state.spaId > 0){
      //console.log("SPA_NOT_NULL: ", this.props.listOfSpas.length);
      return this.state.spaId;
    }else{
      //console.log("SPA: ", 0);
      return 0;
    }
  }

  editorChange = (editorVals, values) => {
    //console.log("VALUES_TEST", values);
    this.props.spaListActionsStuff.initiateUpdateSpaList(editorVals, values);
  };

  componentDidMount() {
    this.props.spaListActionsStuff.getInitialValsSpaList();
    //console.log("ComponentDidMount()");
  }

  async setData(data) {
    const dataWithId = {
      ...data,
      SpaId: this.props.listOfSpas[this.spaSelected()].spaId
    };
   // console.log(dataWithId);
   // console.log(this.props.listOfSpas[this.spaSelected()].spaId);
    return await fetch(`api/spas/${this.props.listOfSpas[this.spaSelected()].spaId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(dataWithId)
    });
  }

  handleClick = () => {
    // const newState = {
    //   ...this.state,
    //   createNew: true,
    //   editSpa: false
    // }

    // this.setState(newState);

    fetch('api/spas/new', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: '{\"Hello\": \"Hello\"}'
    }).then(() => {
      //window.location.reload()
      this.props.spaListActionsStuff.getInitialValsSpaList();
    });
  }

  handleEdit = (spaId) => {
    {console.log("SPA_SELECTED", spaId)}
    const newState = {
      ...this.state,
      createNew: false,
      editSpa: true,
      spaId: spaId
    }

    this.setState(newState);

    this.props.spaListActionsStuff.setSpaSelected(spaId);
   // console.log(this.state);
  }

  deleteMethod = (spaId) => {
    fetch(`api/spas/${spaId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: `{\"id\": ${spaId}}`
    }).then(() => {
      //window.location.reload()
      this.props.spaListActionsStuff.getInitialValsSpaList();
    });
  }

  handleBack = () => {
    const newState = {
      ...this.state,
      createNew: false,
      editSpa: false,
    }

    this.setState(newState);
    this.props.spaListActionsStuff.getInitialValsSpaList();
  }

  updateFile(){
    this.setState({
      ...this.state, imageHash: Date.now()
    });
    //console.log("UPDATE_FILE: ", this.state);
  }

  render() {
    let initialValues;
  console.log("SPA_SELECTED: ", this.spaSelected());
    if(this.props.listOfSpas.length > 0){
      initialValues = {
        receptionEmailAddress: this.props.listOfSpas[this.spaSelected()].receptionEmailAddress,
        name: this.props.listOfSpas[this.spaSelected()].name,
        physicalAddress: this.props.listOfSpas[this.spaSelected()].physicalAddress,
        telephoneNumber: this.props.listOfSpas[this.spaSelected()].telephoneNumber,
        cellNumber: this.props.listOfSpas[this.spaSelected()].cellNumber,
        story: this.props.listOfSpas[this.spaSelected()].story,
        hotelUrl: this.props.listOfSpas[this.spaSelected()].hotelUrl,
        facebookPageUrl: "this.props.facebookPageUrl",
        instagramPageUrl: "this.props.instagramPageUrl",
        instagramUsername:
          this.props.instagramUsername || "this.props.instagramUsername",
        termsAndConditions:this.props.listOfSpas[this.spaSelected()],
        editorState: this.props.listOfSpas[this.spaSelected()].editorState,
        cssLight: this.props.listOfSpas[this.spaSelected()].cssLight
      };
    }else{
      
      let editorStateFromHtml = "";
      let blocksFromHTML = "";

        blocksFromHTML = convertFromHTML(
            "<p>T's and C's</p>"
        );
        editorStateFromHtml = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

      initialValues = {
        receptionEmailAddress: "email@default.com",
        name: "this.props.instagramUsername",
        physicalAddress: "Address",
        telephoneNumber: "01123456",
        cellNumber: "0123456789",
        story: "Some Story",
        hotelUrl: "Some URL",
        facebookPageUrl: "this.props.facebookPageUrl",
        instagramPageUrl: "this.props.instagramPageUrl",
        instagramUsername:
          "this.props.instagramUsername",
        termsAndConditions: "<p>Terms and Conditions</p>",
        editorState: EditorState.createEmpty(),
        cssLight: true
      };
    }

    //console.log(initialValues.editorState);

    const onSubmit = (values, { setSubmitting }) => {
     // console.log("SUBMIT", values.editorState);
      let editorValues = convertToRaw(values.editorState.getCurrentContent());
      setTimeout(() => {
        values.termsAndConditions = draftToHtml(editorValues);
        this.setData(values)
        //.then(response => console.log(response))
        ;
        setSubmitting(false);
      }, 2000);
    };

    const showForm = () => {
      if(this.state.createNew){
        return (
          <React.Fragment>
            <h1>Create New Spa</h1>
          </React.Fragment>
        );
      }else if(this.state.editSpa){
        let TreatmentMenuDesktopUrl;
        if(this.props.listOfSpas[this.spaSelected()] !== null && this.props.listOfSpas[this.spaSelected()] !== undefined){
          if(this.props.listOfSpas[this.spaSelected()].treatmentMenu[0] != null && this.props.listOfSpas[this.spaSelected()].treatmentMenu[0] !== undefined){
            const ind = this.props.listOfSpas[this.spaSelected()].treatmentMenu.length - 1;
            TreatmentMenuDesktopUrl = this.props.listOfSpas[this.spaSelected()].treatmentMenu[ind].desktopUrl;
            //console.log("TREATMENT_MENU_IF_STATEMENT: ", TreatmentMenuDesktopUrl);
          }else{
            TreatmentMenuDesktopUrl = "https://legacybalance.co.za/TreatmentMenus/Spa-1-treatment-menu.pdf";
            //console.log("TREATMENT_MENU_IF_STATEMENT: ", TreatmentMenuDesktopUrl);
          }

        }

// console.log(initialValues);
// console.log(onSubmit);
// console.log(this.editorChange);
// console.log(initialValues.editorState.getCurrentContent());
// console.log(this.handleBack);
// console.log(this.props.listOfSpas[this.spaSelected()].spaId);
// console.log(this.props.listOfSpas[this.spaSelected()].logo.url);
// console.log(this.props.listOfSpas[this.spaSelected()].coverImage.url);
// console.log(this.props.listOfSpas[this.spaSelected()].featuredImage.url);
// console.log(TreatmentMenuDesktopUrl);
// console.log(this.updateFile);

        return (
          <React.Fragment>
            <h1 className="text-center">Edit {this.props.listOfSpas[this.spaSelected()].name}</h1>
            {/* {console.log("LOGO: ", this.props.listOfSpas[this.spaSelected()])} */}
            <hr/>
            <SpaList
          initialValues={initialValues}
          onSubmit={onSubmit}
          editorChange={this.editorChange}
          editorStateInitial={initialValues.editorState}
          back={this.handleBack}
          ID={this.props.listOfSpas[this.spaSelected()].spaId}
          LogoUrl={this.props.listOfSpas[this.spaSelected()].logo.url}
          CoverUrl={this.props.listOfSpas[this.spaSelected()].coverImage.url}
          FeaturedUrl={this.props.listOfSpas[this.spaSelected()].featuredImage.url}
          TreatmentMenuUrl={TreatmentMenuDesktopUrl}
          updateFile={() => this.updateFile()}
         
        />
    <br/>
    
<div className="row">
          <div className="col text-left">
{/* <button onClick={() => this.handleBack()} className="btn btn-primary">Back to list</button> */}

          </div>
</div>
          </React.Fragment>
        );
      }else{
        return (
          <React.Fragment>
           <div className="row">
           <h1>List of spas</h1>
          <div className="col text-right">
          <button
              onClick={() => this.handleClick()}
              className="btn btn-primary"
            >
              Create New Spa
            </button>
            <hr/>
          </div>
           </div>
           
           {this.props.listOfSpas.sort().map((item, index) => {
          return(
            <React.Fragment key={item.spaId}>
              <SpaRow
              Name={item.name}
              email={item.receptionEmailAddress}
              editMethod={this.handleEdit}
              deleteMethod={this.deleteMethod}
              idIndex={index}
              id={item.spaId}/>
             {/* {console.log(item.name)} */}
             {/* {console.log(item.spaId)} */}
             {/* {console.log(item.receptionEmailAddress)} */}
            </React.Fragment>
          );
        })}
          </React.Fragment>
        );
      }
    }

    return (
      <div className="container-fluid" style={{paddingBottom: "50px"}}>
      

        <div>{showForm()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log("SPALIST_FOR_NULL_CHECK: ", state.spaListReducer.spaList);
  return {
    listOfSpas: state.spaListReducer.spaList
  };
}

function mapDispatchToProps(dispatch) {
 // console.log(spaListActions);
 // console.log(dispatch);
  return {
    spaListActionsStuff: bindActionCreators(spaListActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spas);
