import React, {Component} from "react";
import "./Promotion.css";
import Moment from 'react-moment';
import PromotionFormContact from "../../FormContact/PromotionFormContact";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Style from 'style-it';

class Promotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
          promotions: {imagerl: null},
          spaName: "",
          FormType: "",
          modal: false,
          message: "",
          EmailAddress: "",
          firstname: "",
          lastname: "",
          loadingStatus: false,
          backgroundColor: "",
          hoverColor: "",
          shouldToggle: false
        };

        this.toggle = this.toggle.bind(this);
      }

    componentDidMount(){
        let promoData;
        fetch("https://legacybalance.co.za/api/spas/promotions/")
        .then(response => response.json())
        .then(data => {
            // console.log("PROMO: ", data);

           
                for (var i=0; i < data.length; i++) {
                    if (data[i].validSpasList.includes(this.props.url.name)) {
                        promoData = data[i];
                        return promoData;
                    }else{
                        promoData = null;
                    }
                }
            

                // data.find((element) => {
                //     console.log("NAME: ", element.validSpasList.includes(this.props.url.name));
                //     if(element.validSpasList.includes(this.props.url.name)){
                //         console.log("Name: ",element);
                //         return element;
                //     }else{
                //         console.log("Name: ",element);
                //         return {imageUrl: null};
                //     }  
                // })
            }).then((data) => {
                if(promoData !== null){
                    // console.log("NAME: ", data);
                    this.setState({...this.state,
                        promotions: promoData
                });
                }else{
                    // console.log("NAME: ", "Empty");
                    this.setState({...this.state,
                        promotions: "Empty"
                });
                }
            // caches.open('v1').then(function(cache) {
            //     cache.delete('/images/');
            //   })
            })

            fetch(`https://legacybalance.co.za/api/spas/${this.props.url.name}`)
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .then(data => {
                // console.log("CONTACT_FORM: ", data);
                return data;
            })
            .then(data => {
                // console.log("CONTACT_FORM: ", data[0].backgroundColor);
    
                this.setState({
                    ...this.state,
                    backgroundColor: data[0].backgroundColor,
                    hoverColor: data[0].hoverColor
                })
            });
        }

        toggle() {
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
        }

        spinner = () => {
            return (
                <div
                    className="spinner-border"
                    role="status"
                    style={{ marginLeft: "15px" }}
                >
                    <span className="sr-only">Loading...</span>
                </div>
            );
        };

        
        onSubmit = (values, { setSubmitting }) => {
            this.setState({
                ...this.state,
                message: values.message,
                EmailAddress: values.EmailAddress,
                firstname: values.firstname,
                lastname: values.lastname,
                spaName: this.props.spaName,
                FormType: this.props.FormType,
                loadingStatus: true
            });
    
            let populatedBody = {
                message: this.state.message,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                EmailAddress: this.state.EmailAddress,
                spaName: this.props.url.name,
                formType: "Promotion"
            };
    
            fetch("https://legacybalance.co.za/api/contactus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(populatedBody)
            })
                .then(response => response)
                .then(data => {
                    // console.log("VOUCHER_RESULT: ", data.status);
                    return data.status;
                })
                .then((data) => {
                    if(data === 200){
                        setSubmitting(false);
                        this.setState({
                            ...this.state,
                            loadigStatus: false
                        });
                        this.toggle();
                        
                        window.gtag('event', 'conversion', {'send_to': 'AW-661479993/EA8oCNm5uIcDELnEtbsC'});

                        alert("Thank you for contacting us! We'll be in touch soon");
                    }else{
                        setSubmitting(false);
                        this.setState({
                            ...this.state,
                            loadigStatus: false
                        });
                        alert("Something seems to have gone wrong. Please try again");
                    }                    
                });
        };

        setHtml = (markup) => {
            return {__html: markup};
        }

        componentDidUpdate(nextProps, nextState){
            // console.log("UPDATE: Next: ", JSON.stringify(nextState));
            // console.log("UPDATE: ", JSON.stringify(this.state));

            if(this.state === nextState){
                // console.log("UPDATE NOT: ", "NOT THE SAME!!!!!!!!!");
                let promoData;
                fetch("https://legacybalance.co.za/api/spas/promotions/")
                .then(response => response.json())
                .then(data => {
                    // console.log("PROMO: ", data);                         
                        for (var i=0; i < data.length; i++) {
                            if (data[i].validSpasList.includes(this.props.url.name)) {
                                promoData = data[i];
                                return promoData;
                            }else{
                                promoData = null;
                            }
                        }                        
                        // data.find((element) => {
                        //     console.log("NAME: ", element.validSpasList.includes(this.props.url.name));
                        //     if(element.validSpasList.includes(this.props.url.name)){
                        //         console.log("Name: ",element);
                        //         return element;
                        //     }else{
                        //         console.log("Name: ",element);
                        //         return {imageUrl: null};
                        //     }  
                        // })
                    }).then((data) => {
                        if(promoData !== null){
                            // console.log("NAME: ", data);
                            this.setState({...this.state,
                                promotions: promoData
                        });
                        }else{
                            // console.log("NAME: ", "Empty");
                            this.setState({...this.state,
                                promotions: "Empty"
                        });
                        }
                    // caches.open('v1').then(function(cache) {
                    //     cache.delete('/images/');
                    //   })
                    })
            }
        }

        shouldToggle = () => {
            this.setState({
                ...this.state,
                shouldToggle: !this.state.shouldToggle
            });
        }
    

    render(){
        return(
       
           
            (this.state.promotions !== "Empty")? 
            <React.Fragment>

             <div className="spa-promotion-card">    
                <div className="spa-promotion-card__face1"> 
                {console.log('PROMO: ', this.state.promotions)}
                    <img src={this.state.promotions.imageUrl} onClick={() => this.toggle()} alt={this.props.url.name + " promotion"}/>
               
                </div>
                {/* <PromotionFormContact 
                 FormType="Promotion" 
                 spaName={this.props.url.name}
                 allSpaInfo={this.props.url.name}
                 shouldToggle={this.state.shouldToggle}/> */}
             {/* <div className="spa-promotion-card__face2">
                <div className="spa-promotion-card__face2__promo-title">{this.state.promotions.name}</div>
                <div className="spa-promotion-card__face2--start-date"><span>From:</span> 
                    <Moment format="ll">
                        {this.state.promotions.startDate}
                    </Moment>
                </div>
                <div className="spa-promotion-card__face2--end-date"><span>To:</span>
                    <Moment format="ll">
                    {this.state.promotions.endDate}
                    </Moment>
                </div>
                <div className="spa-promotion-card__face2--short-description">
                    <p>{this.state.promotions.shortDescription}. From R{this.state.promotions.price} per person.</p>
                </div>
                <div className="spa-promotion-card__face2--long-description">
                    <p dangerouslySetInnerHTML={this.setHtml(this.state.promotions.longDescription)}></p>
                </div>
             
                 <FormContact 
                 FormType="Promotion" 
                 spaName={this.props.url.name}
                 allSpaInfo={this.props.url.name}/>
                 <div className="spa-promotion-card__face2--t-and-c">
                     <strong>Terms & Conditions</strong>
                     <p>{this.state.promotions.termsAndConditions}</p></div>         
                 
             </div> */}
                <div>
                  <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                  >
                      
                      <ModalHeader toggle={this.toggle}>Contact Us</ModalHeader>
                      <ModalBody>
                          <div className="modal-body">
                              <Formik
                                  enableReinitialize
                                  // initialValues={this.props.initialValues}
                                  validationSchema={Yup.object().shape({
                                      firstname: Yup.string().required("Your first name is required"),
                                      lastname: Yup.string().required("Your last name is required"),
                                      EmailAddress: Yup.string()
                                          .required("Your email address is required")
                                          .email("Invalid email")
                                  })}
                                  onSubmit={this.onSubmit}
                                  initialValues={{
                                      message: this.state.message,
                                      firstname: this.state.firstname,
                                      lastname: this.state.lastname,
                                      EmailAddress: this.state.EmailAddress
                                  }}
                                  render={({
                                               errors,
                                               status,
                                               touched,
                                               isSubmitting,
                                               setFieldValue,
                                               values,
                                               handleBlur
                                           }) => (
                                      <Form>
                                          <div className="form-group">
                                              <label htmlFor="firstname">First Name</label>
                                              <Field
                                                  name="firstname"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.firstname && touched.firstname ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="firstname"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="lastname">Last Name</label>
                                              <Field
                                                  name="lastname"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.lastname && touched.lastname ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="lastname"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="EmailAddress">Email Address</label>
                                              <Field
                                                  name="EmailAddress"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.EmailAddress && touched.EmailAddress ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="EmailAddress"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="message">Message</label>
                                              <Field
                                                  name="message"
                                                  component="textarea"
                                                  className={
                                                      "form-control" +
                                                      (errors.message && touched.message ? " is-invalid" : "")
                                                  }
                                                  //component={() => <textarea className="form-control" placeholder="Type your message here">{values.message}</textarea>}
                                              />
                                              <ErrorMessage
                                                  name="message"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>
                   
                                          <div className="form-group">
                                              <div className="row">
                                                  <div className="col text-left">
                                                  <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
   
   <button
                                                          onClick={() => this.toggle()}
                                                          className="btn btn-primary dynamicButton"
                                                      >
                                                          Cancel
                                                      </button>
              </Style>
                                                  </div>
                                                  <div className="col text-right">
                                                  <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
              <button
                                                          type="submit"
                                                          className="btn btn-primary dynamicButton mr-2"
                                                          disabled={isSubmitting}
                                                      >
                                                          Submit
                                                          {(isSubmitting)? this.spinner() : ""}
                                                          {(this.state.loadingStatus)? this.spinner() : ""}
                                                      </button>
              </Style>
                                                     
                                                  </div>
                                              </div>
                                              {/* <div className="row">
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            disabled={isSubmitting}
          >
            Submit
            {isSubmitting ? this.spinner() : ""}
          </button>
          <button type="reset" className="btn btn-secondary">
            Reset
          </button>
        </div>
      </div> */}
                                          </div>
                                      </Form>
                                  )}
                              />
                              {/* <p>{this.props.FormType}-{this.props.spaName}</p> */}
                          </div>
                      </ModalBody>
                      {/*<ModalFooter>*/}
                      {/*    <Button color="primary" onClick={this.toggle}>*/}
                      {/*        Submit*/}
                      {/*    </Button>{" "}*/}
                      {/*    <Button color="secondary" onClick={this.toggle}>*/}
                      {/*        Cancel*/}
                      {/*    </Button>*/}
                      {/*</ModalFooter>*/}
                  </Modal>
              </div>
              
         </div>
         </React.Fragment>
            :
            <React.Fragment>
            <p>No Promotions Found</p>
            <p>
                {/* {console.log("Name: ", "NOT RENDERED")} */}
            </p>
            </React.Fragment>
            
        );
    };
}
    
export default Promotion;