import React, {Component} from "react";
import "./SpaCard.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import OperationHours from "../OperationHours/OperationHours";
import Radium from 'radium';
import color from 'color';
import Style from 'style-it';
import {Helmet} from "react-helmet";

class SpaCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  lightOrDark(){
    if(this.props.cssLight){
      return "light";
    }else{
      return "dark";
    }
  }

render(){
  let styles = {
    base: {
      backgroundColor: color(this.props.allSpaInfo.backgroundColor),
      borderColor: color(this.props.allSpaInfo.backgroundColor),
      // Adding interactive state couldn't be easier! Add a special key to your
      // style object (:hover, :focus, :active, or @media) with the additional rules.
      ':hover': {
        backgroundColor: color(this.props.allSpaInfo.hoverColor)
      }
    },
    vectors: {
      color: color('#000'),
      cursor: "pointer",
      ':hover': {
        color: color('#FFF'),
        cursor: "pointer",
      }
    }
  };

  return (
    <React.Fragment>
       <Helmet>
                <meta charSet="utf-8" />
  <title>{window.location.href.substring(window.location.href.lastIndexOf('/') + 1).replace(/-/g, ' ')}</title>
  <meta name="description" content={this.props.spaName.replace(/-/g, ' ')} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
 <div className="col-xs-12 col-md-6">
            <div className="spa-card">
              {console.log('LOC: ', window.location.href.substring(window.location.href.lastIndexOf('/') + 1).replace(/-/g, ' '))}
              {console.log('LIST_ORDER: ', this.props)}
              <div
                style={{
                  backgroundImage:
                    `url(\"${this.props.coverUrl}\")`
                }}
                className="spa-card__image"
              >
                <div className={`spa-card__logo-wrapper--${this.lightOrDark()}`}>
                  <img
                    src={this.props.logoUrl}
                    alt={this.props.allSpaInfo.name + " logo"}
                  />
                </div>
              </div>
              <div className="container-fluid spa-card--info">
                <div className="row">
                  <div className="col-12">
                    <div className="spa-card__btn--location" style={styles.vectors}>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="map-marker-alt"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        className="svg-inline--fa fa-map-marker-alt fa-w-12 fa-9x"
                      >
                        <path
                          fill="currentColor"
                          d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                         
                        ></path>
                      </svg>{" "}
                     {this.props.address}
                    </div>
                  </div>
                  <div className="col-6 col-sm-6">
                  <Style>
                          {`
                          .spa-card__btn  {
                            color: A5A5A5;
                            padding: 1.3rem;
                            font-size: 1.6rem;
                            border-bottom: .1rem solid #ededed;
                            width: 100%;
                        }
                        .spa-card__btn:hover  {
                            color: ${this.props.allSpaInfo.hoverColor};
                            cursor: pointer;
                        }
                        .spa-card__btn--icon {
                          display: inline-block;
                          width: 5rem; 
                      }
                        .spa-card__btn--icon svg {
                          height: 3rem;
                          fill:   A5A5A5;
                          margin-bottom: .5rem;
                          width:5rem;
                      }
                      .spa-card__btn--icon svg:hover {
                          color: ${this.props.allSpaInfo.hoverColor};
                      }
                      `}
                    <a href={`tel:${this.props.telephone}`}>
                      <div className="spa-card__btn">
                        <div className="spa-card__btn--icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="phone-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-phone-alt fa-w-16 fa-9x"
                          >
                            <path
                              fill="currentColor"
                              d="M484.25 330l-101.59-43.55a45.86 45.86 0 0 0-53.39 13.1l-32.7 40a311.08 311.08 0 0 1-124.19-124.12l40-32.7a45.91 45.91 0 0 0 13.1-53.42L182 27.79a45.63 45.63 0 0 0-52.31-26.61L35.5 22.89A45.59 45.59 0 0 0 0 67.5C0 313.76 199.68 512.1 444.56 512a45.58 45.58 0 0 0 44.59-35.51l21.7-94.22a45.75 45.75 0 0 0-26.6-52.27zm-41.59 134.09C225.08 463.09 49 287 48 69.3l90.69-20.9 42.09 98.22-68.09 55.71c46.39 99 98.19 150.63 197 197l55.69-68.11 98.19 42.11z"
                              className=""
                            ></path>
                          </svg>
                        </div>
                        <div className="spa-card__btn--text">{this.props.telephone}</div>
                      </div>
                    </a>
                    </Style>
                  </div>
                  <div className="col-6 col-sm-6">
                  <Style>
                          {`
                          .spa-card__btn  {
                            color:  A5A5A5;
                            padding: 1.3rem;
                            font-size: 1.6rem;
                            border-bottom: .1rem solid #ededed;
                            width: 100%;
                        }
                        .spa-card__btn:hover  {
                            color: ${this.props.allSpaInfo.hoverColor};
                            cursor: pointer;
                        }
                        .spa-card__btn--icon {
                          display: inline-block;
                          width: 5rem; 
                      }
                        .spa-card__btn--icon svg {
                          height: 3rem;
                          fill:   A5A5A5;
                          margin-bottom: .5rem;
                          width:5rem;
                      }
                      .spa-card__btn--icon svg:hover {
                          color: ${this.props.allSpaInfo.hoverColor};
                      }
                      `}
                    <a href={`tel:${this.props.cellNumber}`}>
                      <div className="spa-card__btn">
                        <div className="spa-card__btn--icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="mobile-android-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            className="svg-inline--fa fa-mobile-android-alt fa-w-10 fa-9x"
                          >
                            <path
                              fill="currentColor"
                              d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-64 452c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v8zm64-80c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"
                              className=""
                            ></path>
                          </svg>
                        </div>
                        <div className="spa-card__btn--text">{this.props.cellNumber}</div>
                      </div>
                    </a>
                    </Style>
                  </div>
                  <div className="col-6 col-sm-6">
                  <Style>
                          {`
                          .spa-card__btn  {
                            color:  A5A5A5;
                            padding: 1.3rem;
                            font-size: 1.6rem;
                            border-bottom: .1rem solid #ededed;
                            width: 100%;
                        }
                        .spa-card__btn:hover  {
                            color: ${this.props.allSpaInfo.hoverColor};
                            cursor: pointer;
                        }
                        .spa-card__btn--icon {
                          display: inline-block;
                          width: 5rem; 
                      }
                        .spa-card__btn--icon svg {
                          height: 3rem;
                          fill:   A5A5A5;
                          margin-bottom: .5rem;
                          width:5rem;
                      }
                      .spa-card__btn--icon svg:hover {
                          color: ${this.props.allSpaInfo.hoverColor};
                      }
                      `}
                    <a href={`mailto:${this.props.emailAddress}`}>
                      <div className="spa-card__btn">
                        <div className="spa-card__btn--icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="envelope"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-envelope fa-w-16 fa-7x"
                          >
                            <path
                              fill="currentColor"
                              d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                              className=""
                            ></path>
                          </svg>
                        </div>
                        <div className="spa-card__btn--text">Send Email</div>
                      </div>
                    </a>
                    </Style>
                  </div>
                  <div className="col-6 col-sm-6">
                        <Style>
                          {`
                          .spa-card__btn  {
                            color:  A5A5A5;
                            padding: 1.3rem;
                            font-size: 1.6rem;
                            border-bottom: .1rem solid #ededed;
                            width: 100%;
                        }
                        .spa-card__btn:hover  {
                            color: ${this.props.allSpaInfo.hoverColor};
                            cursor: pointer;
                        }
                        .spa-card__btn--icon {
                          display: inline-block;
                          width: 5rem; 
                      }
                        .spa-card__btn--icon svg {
                          height: 3rem;
                          fill:   A5A5A5;
                          margin-bottom: .5rem;
                          width:5rem;
                      }
                      .spa-card__btn--icon svg:hover {
                          color: ${this.props.allSpaInfo.hoverColor};
                      }
                      `}
                    <a className="dynamicSvg">
                      <div className="spa-card__btn" onClick={this.toggle}>
                        <div className="spa-card__btn--icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fal"
                            data-icon="clock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-clock fa-w-16 fa-9x dynamicSvg"
                            >              
                            <path
                              fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"
                              className=""
                              ></path>
                          </svg>

                        </div>
                        <div className="spa-card__btn--text">Open Hours</div>
                      </div>
                    </a>
                              </Style>
                  </div>
              <div className="col-12">
                <div className="spa-card__cta">
                  <Link to={`/spa/${this.props.spaName}`}>
                    <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.props.allSpaInfo.backgroundColor};
                           border-color: ${this.props.allSpaInfo.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.props.allSpaInfo.hoverColor};
                           border-color: ${this.props.allSpaInfo.hoverColor};
                         }
                      `}
                      <div className="btn btn-primary dynamicButton">More Information</div>
                    </Style>

                  </Link>
                    </div>
                    <div>
                      <div className="spa-card__directions">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fad"
                          data-icon="map-marked-alt"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          className="svg-inline--fa fa-map-marked-alt fa-w-18 fa-9x"
                        >
                          <g className="paper">
                            <path
                              d="M554.06 161.16L416 224v288l139.88-55.95A32 32 0 0 0 576 426.34V176a16 16 0 0 0-21.94-14.84zM20.12 216A32 32 0 0 0 0 245.66V496a16 16 0 0 0 21.94 14.86L160 448V214.92a302.84 302.84 0 0 1-21.25-46.42zM288 359.67a47.78 47.78 0 0 1-36.51-17C231.83 319.51 210.92 293.09 192 266v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72A47.8 47.8 0 0 1 288 359.67z"
                              className="fa-secondary"
                            ></path>
                          </g>
                          <g className="pin">
                            <path
                              d="M288 0a126 126 0 0 0-126 126c0 56.26 82.35 158.8 113.9 196a15.77 15.77 0 0 0 24.2 0C331.65 284.8 414 182.26 414 126A126 126 0 0 0 288 0zm0 168a42 42 0 1 1 42-42 42 42 0 0 1-42 42z"
                              className="fa-primary"
                            ></path>
                          </g>
                        </svg>
                        <div className="spa-card__direction-label">
                        <Link to={`/map/${this.props.allSpaInfo.name}`} className="btn">
                        VIEW SPA ON MAP
                        </Link>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          {/* {console.log("MODAL: ", this.props.allSpaInfo)} */}
          <ModalHeader toggle={this.toggle}>{this.props.allSpaInfo.name} - Operation Hours</ModalHeader>
          <ModalBody>
          <div className="modal-body">
                <OperationHours allSpaInfo={this.props.allSpaInfo}/>
            </div>
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" className="btn btn-secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <a href={`tel:${this.props.telephone}`} className="btn btn-primary">                                             
                        Call Now                 
                    </a>
          </ModalFooter>
        </Modal>
      </div>
 </div>
    </React.Fragment>
  );
}
};

export default SpaCard;
