import React, { Component } from "react";
import "./Voucher.css";

const ChooseDesign = props => {
  return (
    <React.Fragment>
      <div className="container choose-voucher">
        <div className="filter-border">
        {props.categories.map((item, index) => {
          return (
            <React.Fragment key={1}>
              {console.log('Vouchers rendered')}
              {(item === 'Christmas')? <a onClick={() => props.filter(item)}>{item}</a> : <React.Fragment></React.Fragment>}
            </React.Fragment>
          );
        })}
        {props.categories.map((item, index) => {
          return (
            <React.Fragment key={1+index}>
              {console.log('Vouchers rendered')}
              {(item !== 'Christmas')? <a onClick={() => props.filter(item)}>{item}</a> :  <React.Fragment></React.Fragment>}                
            </React.Fragment>
          )
        })}
        </div>
        <div className="voucher-grid">
          <div className="row">
            {Object.keys(props.selectedDesignArray).slice(0).reverse().map(function (item, index) {
              return (
                <React.Fragment key={item}>
                  <div className="col-6 col-sm-4 col-md-3">
                    <div
                      className="choose-design-image"
                      key={item + 100}
                    >
                      <img
                        src={props.selectedDesignArray[item].url}
                        alt=""
                        onClick={() =>
                          props.selectedDesign(props.selectedDesignArray[item].url)
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChooseDesign;