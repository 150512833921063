import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { array } from "prop-types";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";

class AvailableSpas extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      spaList: []
    };
  }

  componentDidMount() {
    this.getInitialValsSpaList();
  }

  getInitialValsSpaList() {
    fetch(`api/Spas/`)
      .then(response => {
        //console.log(response);
        return response.json();
      })
      .then(data => {
        let newArray = [];

        data.forEach(spas => {
          newArray.push(spas.name);
        });

        // console.log(newArray);

        this.setState({
          ...this.state,
          spaList: newArray
        });
        //console.log(data);
      });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  getSpas() {
    let spas = this.state.spaList.map((spa, index) => {
      return (
        <React.Fragment key={index}>
          <DropdownItem>
            <NavLink tag={Link} to={`/spa/${spa}`}>
              {spa}
            </NavLink>
          </DropdownItem>
        </React.Fragment>
      );
    });

    return spas;
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>Spas</DropdownToggle>
        <DropdownMenu>
          {this.getSpas()}
          {/* <DropdownItem> 
              <NavLink tag={Link} to={`/spa/${this.spaList[index]}`}>
                   {this.state.spaList[index]}
                  </NavLink>
                  </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default AvailableSpas;
