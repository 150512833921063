import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OperationHours from "../OperationHours/OperationHours";
import Style from 'style-it';

class PromotionFormContact extends Component{
    constructor(props){
        super(props);
        
        this.state = {
            spaName: "",
            FormType: "",
            modal: false,
            message: "",
            EmailAddress: "",
            firstname: "",
            lastname: "",
            loadingStatus: false,
            backgroundColor: "",
            hoverColor: ""
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(){
        fetch(`https://legacybalance.co.za/api/spas/${this.props.spaName}`)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .then(data => {
            // console.log("CONTACT_FORM: ", data);
            return data;
        })
        .then(data => {
            // console.log("CONTACT_FORM: ", data[0].backgroundColor);

            this.setState({
                ...this.state,
                backgroundColor: data[0].backgroundColor,
                hoverColor: data[0].hoverColor
            })
        });

        if(this.props.shouldToggle){
            this.toggle();
        }
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }


    spinner = () => {
        return (
            <div
                className="spinner-border"
                role="status"
                style={{ marginLeft: "15px" }}
            >
                <span className="sr-only">Loading...</span>
            </div>
        );
    };


    onSubmit = (values, { setSubmitting }) => {
        this.setState({
            ...this.state,
            message: values.message,
            EmailAddress: values.EmailAddress,
            firstname: values.firstname,
            lastname: values.lastname,
            spaName: this.props.spaName,
            FormType: this.props.FormType,
            loadingStatus: true
        });

        let populatedBody = {
            message: this.state.message,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            EmailAddress: this.state.EmailAddress,
            spaName: this.state.spaName,
            formType: this.state.FormType
        };

        fetch("https://legacybalance.co.za/api/contactus", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(populatedBody)
        })
            .then(response => response)
            .then(data => {
                // console.log("VOUCHER_RESULT: ", data.status);
                return data.status;
            })
            .then((data) => {
                if(data === 200){
                    setSubmitting(false);
                    this.setState({
                        ...this.state,
                        loadigStatus: false
                    });
                    this.toggle();
                    alert("Thank you for contacting us! We'll be in touch soon");
                }else{
                    setSubmitting(false);
                    this.setState({
                        ...this.state,
                        loadigStatus: false
                    });
                    alert("Something seems to have gone wrong. Please try again");
                }
                
            });
    };

    render(){
        return(
          <React.Fragment>
              
               {/* <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
              </Style> */}
              {/* <div style={{width: "100%", height: "100%"}} onClick={() => this.toggle()}>Contact Us</div>             */}
              {(this.props.shouldToggle)? this.toggle : <React.Fragment></React.Fragment>}
              <div>
                  <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                  >
                      
                      <ModalHeader toggle={this.toggle}>Contact Us</ModalHeader>
                      <ModalBody>
                          <div className="modal-body">
                              <Formik
                                  enableReinitialize
                                  // initialValues={this.props.initialValues}
                                  validationSchema={Yup.object().shape({
                                      firstname: Yup.string().required("Your first name is required"),
                                      lastname: Yup.string().required("Your last name is required"),
                                      EmailAddress: Yup.string()
                                          .required("Your email address is required")
                                          .email("Invalid email")
                                  })}
                                  onSubmit={this.onSubmit}
                                  initialValues={{
                                      message: this.state.message,
                                      firstname: this.state.firstname,
                                      lastname: this.state.lastname,
                                      EmailAddress: this.state.EmailAddress
                                  }}
                                  render={({
                                               errors,
                                               status,
                                               touched,
                                               isSubmitting,
                                               setFieldValue,
                                               values,
                                               handleBlur
                                           }) => (
                                      <Form>
                                          <div className="form-group">
                                              <label htmlFor="firstname">First Name</label>
                                              <Field
                                                  name="firstname"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.firstname && touched.firstname ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="firstname"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="lastname">Last Name</label>
                                              <Field
                                                  name="lastname"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.lastname && touched.lastname ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="lastname"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="EmailAddress">Email Address</label>
                                              <Field
                                                  name="EmailAddress"
                                                  type="text"
                                                  className={
                                                      "form-control" +
                                                      (errors.EmailAddress && touched.EmailAddress ? " is-invalid" : "")
                                                  }
                                              />
                                              <ErrorMessage
                                                  name="EmailAddress"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="message">Message</label>
                                              <Field
                                                  name="message"
                                                  component="textarea"
                                                  className={
                                                      "form-control" +
                                                      (errors.message && touched.message ? " is-invalid" : "")
                                                  }
                                                  //component={() => <textarea className="form-control" placeholder="Type your message here">{values.message}</textarea>}
                                              />
                                              <ErrorMessage
                                                  name="message"
                                                  component="div"
                                                  className="invalid-feedback"
                                              />
                                          </div>
                   
                                          <div className="form-group">
                                              <div className="row">
                                                  <div className="col text-left">
                                                  <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
   
   <button
                                                          onClick={() => this.toggle()}
                                                          className="btn btn-primary dynamicButton"
                                                      >
                                                          Cancel
                                                      </button>
              </Style>
                                                  </div>
                                                  <div className="col text-right">
                                                  <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
              <button
                                                          type="submit"
                                                          className="btn btn-primary dynamicButton mr-2"
                                                          disabled={isSubmitting}
                                                      >
                                                          Submit
                                                          {(isSubmitting)? this.spinner() : ""}
                                                          {(this.state.loadingStatus)? this.spinner() : ""}
                                                      </button>
              </Style>
                                                     
                                                  </div>
                                              </div>
                                              {/* <div className="row">
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            disabled={isSubmitting}
          >
            Submit
            {isSubmitting ? this.spinner() : ""}
          </button>
          <button type="reset" className="btn btn-secondary">
            Reset
          </button>
        </div>
      </div> */}
                                          </div>
                                      </Form>
                                  )}
                              />
                              {/* <p>{this.props.FormType}-{this.props.spaName}</p> */}
                          </div>
                      </ModalBody>
                      {/*<ModalFooter>*/}
                      {/*    <Button color="primary" onClick={this.toggle}>*/}
                      {/*        Submit*/}
                      {/*    </Button>{" "}*/}
                      {/*    <Button color="secondary" onClick={this.toggle}>*/}
                      {/*        Cancel*/}
                      {/*    </Button>*/}
                      {/*</ModalFooter>*/}
                  </Modal>
              </div>
              
          </React.Fragment>  
        );
    }
}

export default PromotionFormContact;