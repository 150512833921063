import { combineReducers } from "redux";
import stuff from "./stuffReducer";
import generalForm from "./generalFormReducer";
import spaListReducer from "./spaListReducer";
import AuthReducer from "./AuthReducer";
import HomeReducer from "./HomeReducer";
import spaPageReducer from "./SpaPageReducer";

const rootReducer = combineReducers({
  stuff,
  generalForm,
  spaListReducer,
  AuthReducer,
  HomeReducer,
  spaPageReducer
});

export default rootReducer;
