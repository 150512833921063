import React, { useState, useCallback, Component } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
//import { photos } from "./photos";

class MasonryGallery extends Component {
  // const [currentImage, setCurrentImage] = useState(0);
  // const [viewerIsOpen, setViewerIsOpen] = useState(false);

constructor(){
  super();

  this.state = {
    currentImage: 0,
    viewerIsOpen: false,
    pictures: []
  }
}

  openLightbox = (event, { photo, index }) => {
    // setCurrentImage(index);
    this.setState({
      ...this.state,
      currentImage: index,
      viewerIsOpen: true
    });
    // setViewerIsOpen(true);    
  }

  closeLightbox = () => {
    this.setState({
      ...this.state,
      currentImage: 0,
      viewerIsOpen: false
    });
  };

  componentDidMount(){

    const photos = [
      // {
      //   src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      //   width: 4,
      //   height: 3
      // }
    ];

    fetch(`https://legacybalance.co.za/api/spas/gallery-images/${this.props.id}`)
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .then(data => 
      {
          // console.log("PHOTOS: ", data[0].featuredImageGallery);

          let collectionOfImages = data[0].featuredImageGallery;

          let pictureArray;
          const foreachFunction = (item, index) => {
            pictureArray =
              {
                src: item.url,
                width: item.width,
                height: item.height,
                alt: item.alt
              };
            photos.push(pictureArray);
          };
          collectionOfImages.forEach(foreachFunction);
         
              this.setState({
                ...this.state,
                pictures: photos
              });
              

      })

   
  }

  render(){

    return (
      <div>
      <div className="masonary-wrapper-spa">
      <Gallery photos={this.state.pictures} onClick={this.openLightbox}/>
      <ModalGateway>
        {this.state.viewerIsOpen ? (
          <Modal onClose={this.closeLightbox}>
            <Carousel
              currentIndex={this.state.currentImage}
              views={this.state.pictures.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
                alt: x.alt
              }))}
              />
          </Modal>
        ) : null}
      </ModalGateway>
      </div>
    </div>
  );
}
}

export default MasonryGallery;