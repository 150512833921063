import {
  TC_EDITOR_CHANGE,
  TC_EDITOR_HTML,
  TC_GET_VALUES
} from "../actions/actionTypes";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import initialState from "./initialState";

export default function generalForm(state = initialState, action) {
  let newState;
  switch (action.type) {
    case TC_EDITOR_CHANGE:
      if (action.editorState != undefined && action.editorState !== null) {
        newState = {
          ...state,
          // stuff: state.stuff,
          editorState: action.editorState
          // emailAddress: state.emailAddress,
          // telephoneNumber: state.telephoneNumber,
          // facebookPageUrl: state.facebookPageUrl,
          // instagramPageUrl: state.instagramPageUrl,
          // instagramUsername: state.instagramUsername,
          // termsAndConditions: state.termsAndConditions
        };
      } else {
        newState = state;
      }
      return newState;
    case TC_EDITOR_HTML:
      if (
        action.termsAndConditions != undefined &&
        action.termsAndConditions !== null
      ) {
        //console.log(action.termsAndConditions);

        return {
          ...state,
          // stuff: state.stuff,
          // editorState: state.editorState,
          // emailAddress: state.emailAddress,
          // telephoneNumber: state.telephoneNumber,
          // facebookPageUrl: state.facebookPageUrl,
          // instagramPageUrl: state.instagramPageUrl,
          // instagramUsername: state.instagramUsername,
          termsAndConditions: action.termsAndConditions
        };
      } else {
        return state;
      }
    case TC_GET_VALUES:
      if (action.initialVals != undefined && action.initialVals !== null) {
        //console.log(action.initialVals);
        let blocksFromHTML;
        let editorStateFromHtml;
        if(action.initialVals.termsAndConditions !== null && action.initialVals.termsAndConditions !== undefined){
          blocksFromHTML = convertFromHTML(
            action.initialVals.termsAndConditions
          );

          editorStateFromHtml = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
        }else{
          blocksFromHTML = convertFromHTML("<p>Terms and Conditions</p>");

          editorStateFromHtml = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
        }
       
        return {
          stuff: state.stuff,
          editorState: EditorState.createWithContent(editorStateFromHtml),
          emailAddress: action.initialVals.emailAddress,
          telephoneNumber: action.initialVals.telephoneNumber,
          facebookPageUrl: action.initialVals.facebookPageUrl,
          instagramPageUrl: action.initialVals.instagramPageUrl,
          instagramUsername: action.initialVals.instagramUsername,
          termsAndConditions: blocksFromHTML,
        };
      } else {
        return state;
      }
    default:
      //console.log("DEFAULT STATE");
      return state;
  }
}
