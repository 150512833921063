import React, { Component } from "react";
import "./SignatureTreatment.css";
import Style from 'style-it';    

class SignatureTreatment extends Component {
    render() {

        return (
            <React.Fragment>
                <Style>
                    {`
                        .btn-dynamic {
                            padding-left: 2rem;
                            padding-right: 2rem;
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            text-transform: uppercase;
                            transition: all .3s;
                            display: inline-block;
                            text-decoration: none;
                        }
                        .btn-primary-dynamic{
                            color: #fff;
                            background-color: ${this.props.AdditionalInfo.backgroundColor};
                            border-color: ${this.props.AdditionalInfo.backgroundColor};
                            border-radius: 0px;
                            margin-bottom: 1rem;
                        }
                        .btn-primary-dynamic:hover {
                            background-color: ${this.props.AdditionalInfo.hoverColor};
                            border-color: ${this.props.AdditionalInfo.hoverColor};
                            color: #fff;
                            box-shadow: 0rem .25rem 1rem rgba(0,0,0,.2);
                            transform: translateY(-2px);
                        }
                        .btn-primary-dynamic:active {
                            box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
                            transform: translateY(1px);
                            background-color: ${this.props.AdditionalInfo.hoverColor};
                            border-color: #a39375;
                        }
                        .btn-primary-dynamic:focus {
                            box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
                        }              
                        `}
                        {console.log('ID: ', this.props.id)}
                        {(this.props.id == "Davinci-Day-Spa-Sandton")?  <a hidden='hidden' target="_blank" href={this.props.TreatmentMenuUrl} download title="Download Treatment Menu" className="btn-dynamic btn-primary-dynamic">Treatment Menu</a>: <a hidden='hidden' target="_blank" href={this.props.TreatmentMenuUrl} download title="Download Treatment Menu" className="btn-dynamic btn-primary-dynamic">Treatment Menu</a>}
                    
                </Style>
            </React.Fragment>
        );
    }
}
    
export default SignatureTreatment;