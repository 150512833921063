import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";

//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl} forceRefresh={true}>
            <App/>
        </BrowserRouter>
    </Provider>,
    rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
