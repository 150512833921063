import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout/Layout";
import Home from "./Containers/Home/Home";
import Vouchers from "./Containers/Vouchers/Vouchers";
import { Map } from "./components/Map/Map";
import { FetchData } from "./components/TemplateStuff/FetchData";
import { Counter } from "./components/TemplateStuff/Counter";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import AllSpasMap from "./components/Map/AllSpasMap";

import StuffList from "./components/TemplateStuff/StuffList";
import General from "./Containers/GeneralAdmin/General";
import Spas from "./Containers/SpaAdmin/Spas";
import Spa from "./Containers/SpaPage/Spa";
import Privacy from "./Containers/Privacy/Privacy";
import VoucherTerms from "./Containers/TermsAndConditions/VoucherTerms";
import ThankYou from "./Containers/ThankYou/ThankYou";
import Cancellation from "./Containers/Cancellation/Cancellation";
import "./custom.css";

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <Layout>
        <Route path="/spa/:id" component={Spa}/>
        <Route exact path="/" component={Home} />
        <AuthorizeRoute path="/fetch-data" component={FetchData} />
        <AuthorizeRoute path="/stufflist" component={StuffList} />
        <AuthorizeRoute path="/general" component={General} />
        <AuthorizeRoute path="/spas" component={Spas} />
        <Route path="/vouchers" component={Vouchers} />
        <Route path="/map/:id" component={Map} />
        <Route path="/all-spas-map" component={AllSpasMap}/>
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Route path="/privacy" component={Privacy}/>
        <Route path="/terms-and-conditions" component={VoucherTerms}/>
        <Route path="/thank-you" component={ThankYou}/>
        <Route path="/cancel" component={Cancellation}/>
      </Layout>
    );
  }
}
