import * as types from "./actionTypes";

export function receiveStuff(json) {
  return { type: types.RECEIVE_STUFF, stuff: json };
}

export function fetchStuff() {
  return dispatch => {
    return fetch("values")
      .then(response => response.json())
      .then(json => {
        dispatch(receiveStuff(json));
        // console.log(json);
      });
  };
}
