import React, { Component } from "react";

// const gtag = () => {
//     const script = document.createElement("script");
//     script.type = "text/javascript";
//     script.src = "https://www.googletagmanager.com/gtag/js?id=AW-661479993";
//     document.head.appendChild(script);
//     window.dataLayer = window.dataLayer || [];   
//     dataLayer.push(arguments);
// };

// const googleConversionTracking = () =>{
//     // <!-- Event snippet for Purchase conversion page --> 
//     gtag('js', new Date());

//     gtag('event', 'conversion', {
//           'send_to': 'AW-661479993/xWKdCNHKkocDELnEtbsC',
//           'transaction_id': ''
//       });        
// };
class ThankYou extends Component {

    // <!-- Global site tag (gtag.js) - Google Ads: 661479993 -->

/* <script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-661479993');
</script> */

googleConversionTracking = () =>{
        // <!-- Event snippet for Purchase conversion page --> 
    
        const voucherValue = localStorage.getItem("VoucherValue");
        const date = new Date();
        const id = date.toISOString();

        window.gtag('event', 'conversion', {
              'send_to': 'AW-661479993/xWKdCNHKkocDELnEtbsC',
              'transaction_id': id,
              'value': voucherValue,
              'currency': 'ZAR'
          });       
          console.log('googleConversionTracking: ', 'Voucher purchase logged');
    }; 

    render(){
        return(
            <React.Fragment>
                {this.googleConversionTracking()}
                <h1>Thank you for Purchasing a Legacy Spa Voucher</h1>
                <p className="voucher-complete-message">Your voucher will be sent to the recipient on the selected date and time. A copy has also been been sent to your inbox for your reference.</p>
            </React.Fragment>
        );
    };

}

export default ThankYou;