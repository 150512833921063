export const FETCH_STUFF = "FETCH_STUFF";
export const RECEIVE_STUFF = "RECEIVE_STUFF";
export const TC_EDITOR_CHANGE = "TC_EDITOR_CHANGE";
export const TC_EDITOR_HTML = "TC_EDITOR_HTML";
export const TC_GET_VALUES = "TC_GET_VALUES";
export const SPA_LIST_EDITOR_CHANGE = "SPA_LIST_EDITOR_CHANGE";
export const SPA_LIST_EDITOR_HTML = "SPA_LIST_EDITOR_HTML";
export const SPA_LIST_GET_VALUES = "SPA_LIST_GET_VALUES";
export const AUTH_STATUS = "AUTH_STATUS";
export const HOME_GET_VALS = "HOME_GET_VALS";
export const Spa_PAGE_GET_VALS = "Spa_PAGE_GET_VALS";
export const SPA_LIST_SET_SELECTED = "SPA_LIST_SET_SELECTED";
export const UPDATE_PROMOTION_EDITOR = "UPDATE_PROMOTION_EDITOR";
