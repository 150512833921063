import React, {Component} from "react";
import "./operationhours.css";

class OperationHours extends Component{

    constructor(){
        super();
    }

    componentDidMount(){

    }

render(){
    return (
      <React.Fragment>
        <div className="open-hours">
          <div className="open-hours-group">
            <div className="open-hours__days">Monday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.monday).getHours()}:00  -{" "}
              {new Date(this.props.allSpaInfo.closingTimeMonday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Tuesday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.tuesday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeTuesday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Wednesday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.wednesday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeWednesday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Thursday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.thursday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeThursday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Friday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.friday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeFriday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Saturday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.saturday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeSaturday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Sunday</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.sunday).getHours()}:00 -{" "}
              {new Date(this.props.allSpaInfo.closingTimeSunday).getHours()}:00
            </div>
          </div>
          <div className="open-hours-group">
            <div className="open-hours__days">Public Holidays</div>
            <div className="open-hours__times">
              {new Date(this.props.allSpaInfo.publicHolidays).getHours()}:00 -{" "}
              {new Date(
                this.props.allSpaInfo.closingTimePublicHolidays
              ).getHours()}:00              
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}


}

export default OperationHours;
