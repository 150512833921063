import {
    Spa_PAGE_GET_VALS
    } from "../actions/actionTypes";
    import { EditorState, ContentState, convertFromHTML } from "draft-js";
    import initialState from "./initialState";
    import { convertToRaw } from "draft-js";
    
    export default function spaPageReducer(state = initialState, action) {
      //console.log("HomeReducer has run");
      //console.log("HomeReducer: ", action);
      let newState;
      switch (action.type) {
        case Spa_PAGE_GET_VALS:
          //console.log("GET_VALUES: ");
    
          if (action.initialVals !== undefined && action.initialVals !== null) {
            //console.log("GET_VALUES: ", action.initialVals[0]);
            
            let editorStateFromHtml = "";
            let blocksFromHTML = "";
  
            //console.log("LENGTH", action.initialVals.length);
    
            let arrayOfSpas = [];
    
            for(let i = 0; i < action.initialVals.length; i++){
    
              if(action.initialVals[i].termsAndConditions !== undefined && action.initialVals[i].termsAndConditions !== null){
                blocksFromHTML = convertFromHTML(
                    action.initialVals[i].termsAndConditions
                );
                editorStateFromHtml = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                //console.log("HTML_CONTENT: ", editorStateFromHtml);
                //console.log("HTML_CONTENT2: ", action.initialVals[i].termsAndConditions);
                //console.log("HTML_CONTENT3: ", EditorState.createWithContent(editorStateFromHtml));
              }else{
                //console.log("REDUCER TERMS AND CONDITIONS: ", action.initialVals[i].termsAndConditions);
                blocksFromHTML = convertFromHTML(
                    "<p>T's and C's</p>"
                );
                editorStateFromHtml = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
              }
    
              //console.log("FOR_LOOP", action.initialVals[i]);
              action.initialVals[i].editorState = EditorState.createWithContent(editorStateFromHtml);
              arrayOfSpas.push(action.initialVals[i]);
              //console.log("ARRAY_OF_SPAS", arrayOfSpas[i].editorState);
            }
    
           // editorState: EditorState.createWithContent(editorStateFromHtml)
    
            const testVals = {
              list: arrayOfSpas
            }
    
            //console.log(JSON.stringify(arrayOfSpas));
            //console.log(testVals);
    
    
            if(action.initialVals.logo !== null && action.initialVals.logo !== undefined){
    
          
    
              return {
                ...state,
                spaList: arrayOfSpas,
                
            //     spaList: [
            //       {
            //         ...state.spaList,
            //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
            //       name: action.initialVals.name,
            //       physicalAddress: action.initialVals.physicalAddress,
            //       telephoneNumber: action.initialVals.telephoneNumber,
            //       cellNumber: action.initialVals.cellNumber,
            //       story: action.initialVals.story,
            //       hotelUrl: action.initialVals.hotelUrl,
            //       editorState: EditorState.createWithContent(editorStateFromHtml),
            //       //editorState:  action.initialVals.termsAndConditions,
            //       logo:
            //   {
            //     logoId:action.initialVals.logo.logoId,
            //         url:action.initialVals.logo.url,
            //       alternativeText:action.initialVals.logo.alternativeText,
            //       description:action.initialVals.logo.description,
            //       title: action.initialVals.logo.title,
            //       folderName: action.initialVals.logo.folderName,
            //       ratio: action.initialVals.logo.ratio,
            //       width: action.initialVals.logo.width,
            //       height: action.initialVals.logo.height
            //   }
            // }
            //     ]
              };
            }else{
              return {
                ...state,
            //     spaList: [
            //       {
            //         ...state.spaList,
            //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
            //       name: action.initialVals.name,
            //       physicalAddress: action.initialVals.physicalAddress,
            //       telephoneNumber: action.initialVals.telephoneNumber,
            //       cellNumber: action.initialVals.cellNumber,
            //       story: action.initialVals.story,
            //       hotelUrl: action.initialVals.hotelUrl,
            //       editorState: EditorState.createWithContent(editorStateFromHtml),
            //       //editorState:  action.initialVals.termsAndConditions,
            // }
            //     ]
            spaList: arrayOfSpas
              };
            }
    
    
          } else {
            //console.log("GET_VALUES: ", action.initialVals);
            return state;
          }
        default:
         // console.log("DEFAULT STATE");
          return state;
      }
    }
    