import { AUTH_STATUS } from "../actions/actionTypes";

let initialState = {
    isAuthenticated: false
}

export default function authState(state = initialState, action) {
  let newState;
  //console.log("AUTH_REDUCER", action);
  switch (action.type) {
    case AUTH_STATUS:
        //console.log("AUTH_REDUCER", action);
        newState = {...state, isAuthenticated: true};
        return newState;
    default:
      return state;
  }
}
