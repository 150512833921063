import * as types from "./actionTypes";
import {
    EditorState,
    ContentState,
    convertFromHTML,
    convertToRaw
  } from "draft-js";
  import draftToHtml from "draftjs-to-html";

export function sendHtmlSpaList(editorVals) {
    //console.log("sendHtml: ", editorVals);

    return {type: types.SPA_LIST_EDITOR_HTML, termsAndConditions: editorVals};
}

export function htmlSpaList(editorValues) {
    //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    return dispatch => {
        return dispatch(sendHtmlSpaList(editorValues));
    };
}

export function doUpdateSpaList(editorVals, values) {
    // console.log("doUpdate: ", convertToRaw(editorVals.getCurrentContent()));
    // let val = convertToRaw(editorVals.getCurrentContent());
     //console.log(values);
    const payload = {
      editorVals: editorVals,
      val: values  
    };
    //console.log(editorVals);
    return {type: types.SPA_LIST_EDITOR_CHANGE, payload: payload,};
}

export function initiateUpdateSpaList(editorValues, values) {
   // console.log(values);
    return dispatch => {
        return dispatch(doUpdateSpaList(editorValues, values));
    };
}

function setInitialValsSpaList(xkzcbvkjbafd) {
    //console.log("32");
   // console.log("33", xkzcbvkjbafd);
    //console.log("34", xkzcbvkjbafd.logoId);
    return {type: types.SPA_LIST_GET_VALUES, initialVals: xkzcbvkjbafd};
    //return { type: types.SPA_LIST_GET_VALUES, spaListGetVals: xkzcbvkjbafd };
}

export function getInitialValsSpaList() {
    return dispatch => {
        fetch("api/Spas/")
            .then(response => {
              //console.log(response);
                return response.json();
            })
            .then(data =>
                dispatch(setInitialValsSpaList(data))
            );
    };
}

function setSelectedSpaId(spaId){
    const payload = {
        selected: spaId  
      };

    return {type: types.SPA_LIST_SET_SELECTED, payload: payload};
}

export function setSpaSelected(id) {
    return dispatch => {
            dispatch(setSelectedSpaId(id));
    };
}

function updateEditor(markup, id){

    const payload = {
        id: id,
        termsAndConditions: markup  
      };

    return {type: types.UPDATE_PROMOTION_EDITOR, payload: payload};
}

export function setEditor(NewEditorState, id){
    const content = NewEditorState.getCurrentContent();
    const rawContentState = convertToRaw(content);
    const markup = draftToHtml(rawContentState);

    return dispatch => {
        dispatch(updateEditor(markup, id));
    }

    // this.setState({
    //   ...this.state,
    //   data: [
    //     {
    //       ...this.state.data[0],
    //       termsAndConditions: markup
    //     }
    //   ]
    // });

    console.log("CURRENT_CONTENT_OF_EDITOR: ", this.state.data[0].termsAndConditions);
}
