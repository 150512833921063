import React, {Component} from 'react';
import PartnerSlider from '../Slider/PartnerSlider';

class Partner extends Component{
    constructor(props){
        super(props);
        this.state = {
            partnerComponentUsed: false
        }
    }

    componentDidMount(){

    }

    render(){
        return (
            <React.Fragment>
                <PartnerSlider spaName={this.props.spaName}/>
            </React.Fragment>
        );
    }
}

export default Partner;