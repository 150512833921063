import React, {Component} from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import "./multislider.css";
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    Modal, ModalHeader, ModalBody, 
    ModalFooter
  } from 'reactstrap';
  import Style from 'style-it';

class PartnerSlider extends Component{

    constructor(props){
        super(props);
        this.state = {
            initialized: false,
            modal: false,
            backgroundColor: "",
            hoverColor: "",
            images: [
                {
                    logoUrl: ""
                }
            ]
        }
    }

    componentDidMount(){
        fetch("https://legacybalance.co.za/api/partner")
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .then(data => {
            // console.log("MULTI: ", data);
            this.setState({
                ...this.state,
                images: data
            })
        });

        fetch(`https://legacybalance.co.za/api/spas/${this.props.spaName}`)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .then(data => {
            this.setState({
                ...this.state,
                backgroundColor: data[0].backgroundColor,
                hoverColor: data[0].hoverColor
            })
        });
    }

    toggle = () => {
        this.setState({
            ...this.state,
            modal: !this.state.modal
        });
    }
    

    render(){
        const responsive = {
            // desktop: {
            //     breakpoint: { max: 3000, min: 1024 },
            //     items: 3,
            //     slidesToSlide: 3, // optional, default to 1.
            // },
            // tablet: {
            //     breakpoint: { max: 1024, min: 464 },
            //     items: 2,
            //     slidesToSlide: 2, // optional, default to 1.
            // },
            // mobile: {
            //     breakpoint: { max: 464, min: 0 },
            //     items: 1,
            //     slidesToSlide: 1, // optional, default to 1.
            // },
        };
        return (
            <div className="spa-products">
                
                {/* <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                > */}
                    {this.state.images.map((item, index) => {
                        return (
                            <div class="product-image-wrapper" key={index}>
                                <Card>
                                    <CardImg top width="100%" src={item.logoUrl} alt="Card image cap" />
                                    <CardBody style={{paddingBottom: "-15px"}}>
                                        <div style={{display: "flex", width: "100%"}}>
                                            <div className="col-12" style={{padding: "0px", marginTop: "10px"}}>
                                                {item.name}
                                            </div>
                                            <div className="col-12" style={{padding: "0px"}}>
                                            <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
              <button className="btn btn-primary dynamicButton" onClick={this.toggle}>More Information</button>
              </Style>
                                                {/* <button className="btn btn-secondary" onClick={this.toggle}>More Information</button> */}
                                            </div>
                                        </div>
                                        {/* <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText> */}
                                    </CardBody>
                                </Card>
                                <Modal isOpen={this.state.modal} toggle={this.toggle} className="container">
                                    <ModalHeader toggle={this.toggle}>{item.name}</ModalHeader>
                                    <ModalBody>
                                        <p>
                                            {item.description}
                                        </p>
                                        <p>
                                            {item.story}
                                        </p>
                                    </ModalBody>
                                    <ModalFooter>
                                    <Style>
                      {`
                         .dynamicButton{
                           background-color: ${this.state.backgroundColor};
                           border-color: ${this.state.backgroundColor};
                         }
                         .dynamicButton:hover {
                           background-color: ${this.state.hoverColor};
                           border-color: ${this.state.hoverColor};
                         }
                      `}
              <button className="btn btn-primary dynamicButton" onClick={this.toggle}>Close</button>
              </Style>
                                        {/* <Button color="primary" onClick={this.toggle}>Close</Button> */}
                                    </ModalFooter>
                                </Modal>
                            </div>
                        );
                    })}
                {/* </Carousel> */}
            </div>
        );
    }

}

export default PartnerSlider;