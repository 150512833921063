import React, { Component } from "react";
import "./Pricelist.css";
import Style from 'style-it';        

class Pricelist extends Component  {

    render(){
        return (
            <React.Fragment>
                <Style>
                    {`
                        .btn-dynamic {
                            padding-left: 2rem;
                            padding-right: 2rem;
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            text-transform: uppercase;
                            transition: all .3s;
                            display: inline-block;
                            text-decoration: none;
                          }
                      .btn-primary-dynamic{
                          color: #fff;
                          background-color: ${this.props.AdditionalInfo.backgroundColor};
                          border-color: ${this.props.AdditionalInfo.backgroundColor};
                          border-radius: 0px;
                          margin-bottom: 1rem;
                        }
                        .btn-primary-dynamic:hover {
                            background-color: ${this.props.AdditionalInfo.hoverColor};
                            border-color: ${this.props.AdditionalInfo.hoverColor};
                        color: #fff;
                        box-shadow: 0rem .25rem 1rem rgba(0,0,0,.2);
                        transform: translateY(-2px);
                    }
                    .btn-primary-dynamic:active {
                        box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
                        transform: translateY(1px);
                        background-color: ${this.props.AdditionalInfo.hoverColor};
                        border-color: #a39375;
                      }
                      .btn-primary-dynamic:focus {
                        box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
                      }                
                    `}
                    {/* <a  href={props.TreatmentMenuUrl} download title="Download Treatment Menu" className="btn btn-primary">Treatment Menu</a> */}
                    <a href={this.props.PricelistUrl} title="Download Pricelist" className="btn-dynamic btn-primary-dynamic">Pricelist</a>
                    {/* {(this.isMobile() == true)?<a href={this.url()} title="Download Pricelist" className="btn-dynamic btn-primary-dynamic">Pricelist</a>:<React.Fragment></React.Fragment>} */}
                </Style>
            </React.Fragment>
        );
    }
}
    
export default Pricelist;