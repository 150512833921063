import {
  SPA_LIST_EDITOR_CHANGE,
  SPA_LIST_EDITOR_HTML,
  SPA_LIST_GET_VALUES,
  SPA_LIST_SET_SELECTED,
  UPDATE_PROMOTION_EDITOR
} from "../actions/actionTypes";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import initialState from "./initialState";
import { convertToRaw } from "draft-js";
import update from 'immutability-helper';

export default function spaListReducer(state = initialState, action) {
  //console.log("SpaListReducer has run");
  //console.log("SpaListReducer", action);
  let newState;
  switch (action.type) {
    case SPA_LIST_EDITOR_CHANGE:
      if (action.payload.editorVals !== undefined && action.payload.editorVals !== null) {
        //console.log("17");
       // console.log(convertToRaw(action.payload.editorVals.getCurrentContent()));
        //console.log("19", action.payload);
        newState = {
          ...state,
          spaList: [{ ...state.spaList, spaList: action.payload.val, editorState: action.payload.editorVals }]
        };
      } else {
        newState = state;
      }
      return newState;
    case SPA_LIST_EDITOR_HTML:
      if (
        action.termsAndConditions != undefined &&
        action.termsAndConditions !== null
      ) {
        //console.log(action.termsAndConditions);

        return {
          ...state,
          spaList: [
            { ...state.spaList, termsAndConditions: action.termsAndConditions }
          ]
        };
      } else {
        return state;
      }
    case SPA_LIST_GET_VALUES:
      //console.log("GET_VALUES: ");

      if (action.initialVals !== undefined && action.initialVals !== null) {
        //console.log("GET_VALUES: ", action.initialVals[0]);
        
        let editorStateFromHtml = "";
        let blocksFromHTML = "";

        //console.log("LENGTH", action.initialVals.length);

        let arrayOfSpas = [];

        for(let i = 0; i < action.initialVals.length; i++){

          if(action.initialVals[i].termsAndConditions !== undefined && action.initialVals[i].termsAndConditions !== null){
            blocksFromHTML = convertFromHTML(
                action.initialVals[i].termsAndConditions
            );
            editorStateFromHtml = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            //console.log("HTML_CONTENT: ", editorStateFromHtml);
            //console.log("HTML_CONTENT2: ", action.initialVals[i].termsAndConditions);
            //console.log("HTML_CONTENT3: ", EditorState.createWithContent(editorStateFromHtml));
          }else{
            //console.log("REDUCER TERMS AND CONDITIONS: ", action.initialVals[i].termsAndConditions);
            blocksFromHTML = convertFromHTML(
                "<p>T's and C's</p>"
            );
            editorStateFromHtml = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
          }

          //console.log("FOR_LOOP", action.initialVals[i]);
          action.initialVals[i].editorState = EditorState.createWithContent(editorStateFromHtml);
          arrayOfSpas.push(action.initialVals[i]);
          //console.log("ARRAY_OF_SPAS", arrayOfSpas[i].editorState);
        }

       // editorState: EditorState.createWithContent(editorStateFromHtml)

        const testVals = {
          list: arrayOfSpas
        }

        //console.log(JSON.stringify(arrayOfSpas));
        //console.log(testVals);

        if(action.initialVals.logo !== null && action.initialVals.logo !== undefined){

          return {
            ...state,
            spaList: arrayOfSpas,
            
        //     spaList: [
        //       {
        //         ...state.spaList,
        //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
        //       name: action.initialVals.name,
        //       physicalAddress: action.initialVals.physicalAddress,
        //       telephoneNumber: action.initialVals.telephoneNumber,
        //       cellNumber: action.initialVals.cellNumber,
        //       story: action.initialVals.story,
        //       hotelUrl: action.initialVals.hotelUrl,
        //       editorState: EditorState.createWithContent(editorStateFromHtml),
        //       //editorState:  action.initialVals.termsAndConditions,
        //       logo:
        //   {
        //     logoId:action.initialVals.logo.logoId,
        //         url:action.initialVals.logo.url,
        //       alternativeText:action.initialVals.logo.alternativeText,
        //       description:action.initialVals.logo.description,
        //       title: action.initialVals.logo.title,
        //       folderName: action.initialVals.logo.folderName,
        //       ratio: action.initialVals.logo.ratio,
        //       width: action.initialVals.logo.width,
        //       height: action.initialVals.logo.height
        //   }
        // }
        //     ]
          };
        }else{
          return {
            ...state,
        //     spaList: [
        //       {
        //         ...state.spaList,
        //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
        //       name: action.initialVals.name,
        //       physicalAddress: action.initialVals.physicalAddress,
        //       telephoneNumber: action.initialVals.telephoneNumber,
        //       cellNumber: action.initialVals.cellNumber,
        //       story: action.initialVals.story,
        //       hotelUrl: action.initialVals.hotelUrl,
        //       editorState: EditorState.createWithContent(editorStateFromHtml),
        //       //editorState:  action.initialVals.termsAndConditions,
        // }
        //     ]
        spaList: arrayOfSpas
          };
        }


      } else {
        //console.log("GET_VALUES: ", action.initialVals);
        return state;
      }
      case SPA_LIST_SET_SELECTED:

        // console.log("SPA_LIST_SELECTED: ", action.payload);

        //   if (action.payload.initialVals !== undefined && action.payload.initialVals !== null) {
        //     console.log("GET_VALUES: ", action.payload.initialVals[0]);
            
        //     let editorStateFromHtml = "";
        //     let blocksFromHTML = "";
    
        //     //console.log("LENGTH", action.initialVals.length);
    
        //     let arrayOfSpas = [];
    
        //     for(let i = 0; i < action.payload.initialVals.length; i++){
    
        //       if(action.payload.initialVals[i].termsAndConditions !== undefined && action.payload.initialVals[i].termsAndConditions !== null){
        //         blocksFromHTML = convertFromHTML(
        //             action.payload.initialVals[i].termsAndConditions
        //         );
        //         editorStateFromHtml = ContentState.createFromBlockArray(
        //             blocksFromHTML.contentBlocks,
        //             blocksFromHTML.entityMap
        //         );
        //         //console.log("HTML_CONTENT: ", editorStateFromHtml);
        //         //console.log("HTML_CONTENT2: ", action.initialVals[i].termsAndConditions);
        //         //console.log("HTML_CONTENT3: ", EditorState.createWithContent(editorStateFromHtml));
        //       }else{
        //         //console.log("REDUCER TERMS AND CONDITIONS: ", action.initialVals[i].termsAndConditions);
        //         blocksFromHTML = convertFromHTML(
        //             "<p>T's and C's</p>"
        //         );
        //         editorStateFromHtml = ContentState.createFromBlockArray(
        //             blocksFromHTML.contentBlocks,
        //             blocksFromHTML.entityMap
        //         );
        //       }
    
        //       //console.log("FOR_LOOP", action.initialVals[i]);
        //       action.payload.initialVals[i].editorState = EditorState.createWithContent(editorStateFromHtml);
        //       arrayOfSpas.push(action.payload.initialVals[i]);
        //       //console.log("ARRAY_OF_SPAS", arrayOfSpas[i].editorState);
        //     }
    
        //    // editorState: EditorState.createWithContent(editorStateFromHtml)
    
        //     const testVals = {
        //       list: arrayOfSpas
        //     }
    
        //     //console.log(JSON.stringify(arrayOfSpas));
        //     //console.log(testVals);
    
        //     if(action.payload.initialVals.logo !== null && action.payload.initialVals.logo !== undefined){
    
        //       return {
        //         ...state,
        //         spaList: arrayOfSpas,
        //         selectedSpaId: action.payload.selected
        //     //     spaList: [
        //     //       {
        //     //         ...state.spaList,
        //     //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
        //     //       name: action.initialVals.name,
        //     //       physicalAddress: action.initialVals.physicalAddress,
        //     //       telephoneNumber: action.initialVals.telephoneNumber,
        //     //       cellNumber: action.initialVals.cellNumber,
        //     //       story: action.initialVals.story,
        //     //       hotelUrl: action.initialVals.hotelUrl,
        //     //       editorState: EditorState.createWithContent(editorStateFromHtml),
        //     //       //editorState:  action.initialVals.termsAndConditions,
        //     //       logo:
        //     //   {
        //     //     logoId:action.initialVals.logo.logoId,
        //     //         url:action.initialVals.logo.url,
        //     //       alternativeText:action.initialVals.logo.alternativeText,
        //     //       description:action.initialVals.logo.description,
        //     //       title: action.initialVals.logo.title,
        //     //       folderName: action.initialVals.logo.folderName,
        //     //       ratio: action.initialVals.logo.ratio,
        //     //       width: action.initialVals.logo.width,
        //     //       height: action.initialVals.logo.height
        //     //   }
        //     // }
        //     //     ]
        //       };
        //     }else{
        //       return {
        //         ...state,
        //     //     spaList: [
        //     //       {
        //     //         ...state.spaList,
        //     //         receptionEmailAddress: action.initialVals.receptionEmailAddress,
        //     //       name: action.initialVals.name,
        //     //       physicalAddress: action.initialVals.physicalAddress,
        //     //       telephoneNumber: action.initialVals.telephoneNumber,
        //     //       cellNumber: action.initialVals.cellNumber,
        //     //       story: action.initialVals.story,
        //     //       hotelUrl: action.initialVals.hotelUrl,
        //     //       editorState: EditorState.createWithContent(editorStateFromHtml),
        //     //       //editorState:  action.initialVals.termsAndConditions,
        //     // }
        //     //     ]
        //     spaList: arrayOfSpas,
        //     selectedSpaId: action.payload.selected
        //       };
        //     }
    
    
        //   } else {
        //     //console.log("GET_VALUES: ", action.initialVals);
        //     return state;
        //   }

        // let newSelectedState = {
        //   ...initialState,
        //   selectedSpaId: action.selected
        // };

       // return newSelectedState;

      //  console.log("SPALIST_FOR_NULL_CHECK_UPDATE: ", action.payload.selected);
       return update(state, {
        selectedSpaId: {$set: action.payload.selected}
       })
       
       case UPDATE_PROMOTION_EDITOR:

          return update(state, {
              spaList: {
                [action.payload.id]:
                {
                  promotion: {
                    termsAndConditions: {$set: action.payload.termsAndConditions}
                  }

                }
            }
          })
        
    default:
      //console.log("DEFAULT STATE");
      return state;
  }
}
