import React from "react";
import "./VoucherBanner.css";

const VoucherBanner = props => {
  return <React.Fragment>
    <div className="voucher-banner-wrapper">
      <div className="voucher-box">
        <div className="voucher-box__image">
          <img src="https://legacybalance.co.za/VoucherBanner/animated-voucher-banner.gif" />
        </div>
        <div className="voucher-box__info">
          <h2>Spa Gift Vouchers</h2>
          <p>Create a voucher and send it by email, either immediately or on a specific date.</p>
          <a href="https://legacybalance.co.za/vouchers" className="btn btn-primary">Create a Voucher</a>
          <p>Redeemable at all Legacy Balance Spas</p>
         
        </div>
      </div>
      
     
    </div>
  </React.Fragment>;
};

export default VoucherBanner;
