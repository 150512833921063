import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Voucher from "../../components/Voucher/Voucher";
import ChooseDesign from "../../components/Voucher/ChooseDesign";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withFormik } from "formik";
import DateTime from "react-datetime";
import "./datetimpicker.css";
import Moment from "react-moment";
import { fetch } from 'whatwg-fetch';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { relativeTimeThreshold } from "moment";

class Vouchers extends Component {
  constructor() {
    super();

    this.state = {
      emailSent: false,
      activeTab: 1,
      step: 0,
      selectedIndex: -1,
      selectedImageUrl: "",
      dateTimeSelected: new Date(),
      message: "",
      value: 500,
      recipientFirstname: "",
      recipientLastname: "",
      schedule: "",
      recipientEmailAddress: "",
      firstname: "",
      lastname: "",
      senderEmailAddress: "",
      voucherIdentifier: Math.random(),
      categoryAlreadyRendered: [],
      categorySpecificImages: [],
      selectedDesignArray: [
        {
          url: "https://legacybalance.co.za/image.jpg"
        }
      ],
      designArray: [
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        },
        {
          url: "https://legacybalance.co.za/image.jpg"
        }
      ]
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    fetch("https://legacybalance.co.za/api/voucherdesign/")
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .then(data => {
        let arr = [];
        for (var i = 0; i < data.length; i++) {
          arr.push(data[i]);

        }

        let allArr = [];
        let count = arr.length;
        for (var i = 0; i < arr.length; i++) {
          let all = {
            id: count + 3,
            url: arr[i].url,
            category: "All",
            alt: null,
            description: null,
          }
          allArr.push(all);
          count++;
        }

        for (var i = 0; i < allArr.length; i++) {
          arr.push(allArr[i]);
        }

        this.setState({
          ...this.state,
          designArray: arr,

        });

        this.setState({
          ...this.state,
          selectedDesignArray: this.initialFilter("All")
        })

        // console.log("CATEGORIES_SELECTED: ", this.state.selectedDesignArray);

        // console.log("CATEGORIES_ARR: ", this.state.designArray);


        let catArray = [];
        for (var i = 0; i < data.length; i++) {
          catArray.push("All");
          catArray.push(data[i].category);
        }

        // console.log("CATEGORIES: ", catArray);

        const categories = [...new Set(catArray)];
        let catSpecificImagesObject = {};
        let catParentObject = [];

        let tempObjectAll = [];
        let tempJsonAll = {};

        for (var i = 0; i < data.length; i++) {
          tempJsonAll = {
            url: data[i].url,
            category: "All"
          };
          tempObjectAll.push(tempJsonAll);
        }

        // catSpecificImagesObject = { 
        //   "All": tempObjectAll
        // };
        catParentObject.push(tempObjectAll);

        // console.log("CATEGORIES_PARENT_OBJECT: ", catParentObject);
        for (var j = 0; j < categories.length; j++) {
          let tempObject = [];
          let tempJson = {};
          if (categories[j] !== "All") {
            for (var i = 0; i < data.length; i++) {
              // console.log(`CATEGORIES_PARENT_OBJECT ${i}: `, catParentObject);  
              if (categories[j] === data[i].category) {
                tempJson = {
                  url: data[i].url,
                  category: data[i].category
                };

                tempObject.push(tempJson);
              }
            }
            catSpecificImagesObject = {
              [categories[j]]: tempObject
            };
            catParentObject.push(tempObject);
            // console.log("CATEGORIES_PARENT_OBJECT: ", catParentObject);
          }
        }
        this.setState({
          ...this.state,
          categoryAlreadyRendered: categories,
          categorySpecificImages: catParentObject
        });
        // console.log("CATEGORIES_SET: ", this.state.categoryAlreadyRendered);
      });
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        ...this.state,
        activeTab: tab
      });
    }
  };

  selectedDesign = index => {
    this.setState({
      ...this.state,
      step: 1,
      selectedIndex: index
    });
  };

  incrementStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step + 1
    });
    window.scrollTo(0, 0);
  };

  decrementStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step - 1
    });
    window.scrollTo(0, 0);
  };

  spinner = () => {
    return (
      <div
        className="spinner-border"
        role="status"
        style={{ marginLeft: "15px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  buildTabs = () => {
    let catArray = [];
    this.state.designArray.map((item, index) => {
      if (!catArray.find(() => item.category)) {
        if(item.category === "Christmas") {
          catArray.push(item.category);
        }
      }
    });
    this.state.designArray.map((item, index) => {      
      if (!catArray.find(() => item.category)) {
        catArray.push(item.category);
      }
    });
    // console.log("CATEGORIES_BUILD_TABS: ", catArray);
    this.setState({
      ...this.state,
      categoryAlreadyRendered: catArray
    });
  };

  buildImageGrid = arr => {
    let tabIds = [];
    let tabNames = [];
    return (
      <React.Fragment>
        {/* <Nav tabs> */}
        {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 100
                    })}
                    onClick={() => {
                      this.toggle(100);
                    }}
                  >
                    All
                  </NavLink>
                </NavItem> */}

        {/* {this.state.categoryAlreadyRendered.map((i, jIndex) => {
            tabIds.push(jIndex);
            tabNames.push(i);
            return (
              <React.Fragment key={jIndex}>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === jIndex
                    })}
                    onClick={() => {
                      this.toggle(jIndex);
                    }}
                  >
                    {i}
                  </NavLink>
                </NavItem>

              </React.Fragment>
            );
          })}
           </Nav> */}
        <div className="voucher-grid">

        {Object.keys(this.state.designArray).map((item, index) => {
          return (
            <React.Fragment key={200+index}>
              {(item.category === 'Christmas')? 
              <React.Fragment key={index}>
                <div
                  className="choose-design-image"
                  key={index}
                // style={{width: "200px"}}
                >
                  <img
                    src={item.url}
                    alt=""
                    onClick={() =>
                      this.selectedDesign(item.url)
                    }
                  // style={{width: "200px"}}
                  />
                </div>

              </React.Fragment>
              : <React.Fragment key={100+index}/>
              
          
        }
        </React.Fragment>
          )
          }
        )
        }


          {Object.keys(this.state.designArray).map((item, index) => {
            return (
              <React.Fragment key={index}>
                {(item.category !== 'Christmas')? 
                <div
                  className="choose-design-image"
                  key={index}
                // style={{width: "200px"}}
                >
                  <img
                    src={item.url}
                    alt=""
                    onClick={() =>
                      this.selectedDesign(item.url)
                    }
                  // style={{width: "200px"}}
                  />
                </div>
                : <React.Fragment key={150+index}/>
                  }
              </React.Fragment>
            );
          })}
        </div>

      </React.Fragment>
    );
  };

  changeDesign = () => {
    this.setState({
      ...this.state,
      step: 0
    });
  };

  onSubmit = (values, { setSubmitting }) => {
    this.incrementStep();
    // console.log("X: ", this.state.voucherIdentifier);
    this.setState({
      ...this.state,
      message: values.message,
      value: values.value,
      recipientFirstname: values.recipientFirstname,
      recipientLastname: values.recipientLastname,
      schedule: values.schedule,
      recipientEmailAddress: values.recipientEmailAddress,
      firstname: values.firstname,
      lastname: values.lastname,
      senderEmailAddress: values.senderEmailAddress,
      voucherImageUrl: this.state.selectedIndex
    });
    // console.log("X: ", this.state.voucherIdentifier);

    setSubmitting(false);
  };

  createVoucher = () => {
    let populatedBody = {
      message: this.state.message,
      value: this.state.value,
      recipientFirstname: this.state.recipientFirstname,
      recipientLastname: this.state.recipientLastname,
      schedule: this.state.schedule,
      recipientEmailAddress: this.state.recipientEmailAddress,
      buyerFirstName: this.state.firstname,
      buyerLastName: this.state.lastname,
      buyerEmailAddress: this.state.senderEmailAddress,
      toSend: this.state.dateTimeSelected,
      voucherIdentifier: this.state.voucherIdentifier,
      voucherImageUrl: this.state.selectedIndex
    };

    localStorage.setItem("VoucherValue", populatedBody.value);

    this.incrementStep();
    fetch("https://legacybalance.co.za/api/voucher", {
      method: "POST",
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json"
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(populatedBody)
    })
      .then(response => {
        if (response.status === 200) {
          console.log("Thank you FireFox!");
          this.setState({
            ...this.state,
            emailSent: true
          });
          // this.incrementStep();
          document.getElementById("PayfastForm").submit()
        } else {
          console.log("STATUS: ", response.status);
        }
      })
      .catch((error) => {
        console.log("AN ERROR HAS OCCURRED: ", error.message);
        //this.incrementStep();
      });
  };

  filter = (filterby) => {
    var newArray = this.state.designArray.filter(function (el) {
      return el.category === filterby;
    });

    this.setState({
      ...this.state,
      selectedDesignArray: newArray
    })
  }

  initialFilter = (filterby) => {
    var newArray = this.state.designArray.filter(function (el) {
      return el.category === filterby;
    });

    return newArray;
  }

  returnStep = step => {
    if (this.state.step === 0) {
      return (
        <ChooseDesign
          selectedDesign={this.selectedDesign}
          selectedIndex={this.state.selectedIndex}
          designArray={this.state.designArray}
          buildImageGrid={() => this.buildImageGrid()}
          toggle={this.toggle}
          activeTab={this.state.activeTab}
          filter={this.filter}
          categories={this.state.categoryAlreadyRendered}
          selectedDesignArray={this.state.selectedDesignArray}
        />
      );
    } else if (this.state.step === 1) {
      return (
        <React.Fragment>
          <div className="voucher-form">
            <div className="voucher-form__image">
              <img
                src={this.state.selectedIndex}
                alt="{{Voucher Image Category}}"
              />
              <div
                className="voucher-form__image--change-button"
                onClick={() => this.changeDesign()}
              >
                Change
              </div>
            </div>
            <br />
            <div className="voucher-form__info">
              {/* Start Formik */}
              <Formik
                enableReinitialize
                validationSchema={Yup.object().shape({
                  value: Yup.number().required("Value is required"),
                  recipientFirstname: Yup.string().required(
                    "Recipient First Name is required"
                  ),
                  recipientLastname: Yup.string().required(
                    "Recipient Last Name is required"
                  ),
                  recipientEmailAddress: Yup.string()
                    .required("Recipient email address is required")
                    .email("Invalid email"),
                  firstname: Yup.string().required(
                    "Your first name is required"
                  ),
                  lastname: Yup.string().required("Your last name is required"),
                  senderEmailAddress: Yup.string()
                    .required("Your email address is required")
                    .email("Invalid email")
                })}
                onSubmit={this.onSubmit}
                initialValues={{
                  message: this.state.message,
                  value: this.state.value,
                  recipientFirstname: this.state.recipientFirstname,
                  recipientLastname: this.state.recipientLastname,
                  schedule: this.state.schedule,
                  recipientEmailAddress: this.state.recipientEmailAddress,
                  firstname: this.state.firstname,
                  lastname: this.state.lastname,
                  senderEmailAddress: this.state.senderEmailAddress
                }}
                render={({
                  errors,
                  status,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  values,
                  handleBlur
                }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="value">
                          Voucher Value (South African Rand)
                          </label>
                        <Field
                          name="value"
                          type="number"
                          className={
                            "form-control" +
                            (errors.value && touched.value ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="value"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <fieldset>
                        <legend>Recipient Details</legend>
                        <div className="form-group">
                          <label htmlFor="recipientFirstname">
                            Recipient First Name
                            </label>
                          <Field
                            name="recipientFirstname"
                            type="text"
                            className={
                              "form-control" +
                              (errors.recipientFirstname &&
                                touched.recipientFirstname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="recipientFirstname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="recipientLastname">
                            Recipient Last Name
                            </label>
                          <Field
                            name="recipientLastname"
                            type="text"
                            className={
                              "form-control" +
                              (errors.recipientLastname && touched.recipientLastname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="recipientLastname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="recipientEmailAddress">
                            Recipient Email Address
                            </label>
                          <Field
                            name="recipientEmailAddress"
                            type="email"
                            className={
                              "form-control" +
                              (errors.recipientEmailAddress &&
                                touched.recipientEmailAddress
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="recipientEmailAddress"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="message">Message (Optional)</label>
                          <Field
                            name="message"
                            component="textarea"
                            className={
                              "form-control" +
                              (errors.message && touched.message
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </fieldset>


                      <fieldset>
                        <legend>Sender Details</legend>
                        <div className="form-group">
                          <label htmlFor="firstname">Sender First Name</label>
                          <Field
                            name="firstname"
                            type="text"
                            className={
                              "form-control" +
                              (errors.firstname && touched.firstname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastname">Sender Last Name</label>
                          <Field
                            name="lastname"
                            type="text"
                            className={
                              "form-control" +
                              (errors.lastname && touched.lastname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="senderEmailAddress">
                            Sender Email Address
                            </label>
                          <Field
                            name="senderEmailAddress"
                            type="email"
                            className={
                              "form-control" +
                              (errors.senderEmailAddress &&
                                touched.senderEmailAddress
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Options</legend>
                        <div className="form-group">
                          <div className="form-check">
                            <Field
                              id="scheduled"
                              name="scheduled"
                              type="radio"
                              value="scheduled"
                              checked={values.schedule === "scheduled"}
                              onChange={() =>
                                setFieldValue("schedule", "scheduled")
                              }
                              className="form-check-input"
                            />
                            <label
                              htmlFor="scheduled"
                              className="form-check-label"

                            >
                              Send Now
                              </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <Field
                              className="form-check-input"
                              id="doSchedule"
                              name="doSchedule"
                              type="radio"
                              value="doSchedule"
                              checked={values.schedule === "doSchedule"}
                              onChange={() =>
                                setFieldValue("schedule", "doSchedule")
                              }
                            />
                            <label
                              htmlFor="doSchedule"
                              className="form-check-label"
                              style={{ marginLeft: "12px" }}
                            >
                              Schedule a time to send?
                            </label>
                          </div>
                        </div>
                        {values.schedule === "doSchedule" ? (
                          <React.Fragment>
                            <div className="form-group">
                              <label>Select Date and Time to send voucher</label>
                              <DateTime
                                onChange={this.setDate}
                                defaultValue={this.state.dateTimeSelected}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                            <p>Email will be sent as soon as payment is processed</p>
                          )}
                      </fieldset>
                      <div className="form-group">
                        <div className="row">
                          <div className="col text-left">
                            <button
                              onClick={() => this.decrementStep()}
                              className="btn btn-primary"
                            >
                              Back
                          </button>
                          </div>
                          <div className="col text-right">
                            <button className="btn btn-primary" type="submit">
                              Next
                          </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
              />
              {/* End Formik */}
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.step === 2) {
      return (
        <React.Fragment>
          <div className="container voucher-details">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <fieldset>
                  {/* {console.log("STATE_CHECK: ", this.state)} */}
                  <legend>Recipient</legend>
                  <strong>Name:</strong> {this.state.recipientFirstname}<br />
                  <strong>Surname:</strong> {this.state.recipientLastname}<br />
                  <strong>Email</strong> {this.state.recipientEmailAddress}<br />
                  <strong>Delivery Date:</strong> {this.state.dateTimeSelected.toUTCString()}<br />
                </fieldset>
              </div>
              <div className="col-sm-12 col-md-6">
                <fieldset>
                  <legend>Sender</legend>
                  <strong>Name:</strong> {this.state.firstname}<br />
                  <strong>Surname:</strong> {this.state.lastname}<br />
                  <strong>Email:</strong> {this.state.senderEmailAddress}
                </fieldset>
              </div>
            </div>
          </div>
          <div className="voucher-form">

            <form
              id="PayfastForm"
              action="https://www.payfast.co.za/eng/process"
               method="POST" 
             > 
{/* <form
              id="PayfastForm"
              action="https://sandbox.payfast.co.za/eng/process"
               method="POST" 
             >  */}



              <input type="hidden" name="merchant_id" value="14531448" />
              <input type="hidden" name="merchant_key" value="qjy6tdfpb6v9r" />
              {/* <input type="hidden" name="merchant_id" value="10015462" />
              <input type="hidden" name="merchant_key" value="o20qo74zh8bq7" /> */}
              <input type="hidden" name="custom_str1" value={this.state.voucherIdentifier} />
              <input
                type="hidden"
                name="return_url"
                value="https://legacybalance.co.za/thank-you"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="https://legacybalance.co.za/cancel"
              />
              <input
                type="hidden"
                name="notify_url"
                value="https://adminpanel.legacyspas.co.za/api/default"
              />

              <input
                type="hidden"
                name="name_first"
                value={this.state.firstname}
              />
              <input
                type="hidden"
                name="name_last"
                value={this.state.lastname}
              />
              <input
                type="hidden"
                name="email_address"
                value={this.state.senderEmailAddress}
              />
              <input type="hidden" name="m_payment_id" value={this.state.voucherIdentifier} />
              <input type="hidden" name="amount" value={this.state.value} />
              <input
                type="hidden"
                name="item_name"
                value="Spa Voucher"
              />
              <input type="hidden" name="item_description" value="Spa Voucher" />
              <input type="hidden" name="email_confirmation" value="1" />
              {/* <input
                type="hidden"
                name="confirmation_address"
                value="margarita@legacybalance.co.za"
              /> */}
              <input
                type="hidden"
                name="confirmation_address"
                value="margarita@legacybalance.co.za"
              />
              <input type="hidden" name="payment_method" value="cc" />

              {/* <div className="row">
                <div className="col text-left">
                  <button
                    onClick={() => this.decrementStep()}
                    className="btn btn-primary"
                  >
                    Back
                  </button>
                </div>
                <div className="col text-right">
                  <button type="submit" className="btn btn-success btn-block">
                    Proceed to payment
                  </button>
                </div>
              </div> */}

              <div className="voucher-form__image">
                <img
                  src={this.state.selectedIndex}
                  alt="{{Voucher Image Category}}"
                />
                <div
                  className="voucher-form__image--change-button"
                  onClick={() => this.changeDesign()}
                >
                  Edit Voucher
              </div>
              </div>
              <br />
              <div className="voucher-form__proof-info">
                <h2>Value: R{this.state.value}</h2>
                <p>{this.state.message}</p>
              </div>
              <div className="voucher-form__recipient"></div>
              <div className="row">
                <div className="col-3 text-left">
                  <button
                    onClick={() => this.decrementStep()}
                    className="btn btn-primary"
                  >
                    Edit
                </button>
                </div>
                <div className="col-9 text-right">

                  <button
                    onClick={() => this.createVoucher()}
                    className="btn btn-success btn-block"
                  >
                    Proceed to payment
                </button>
                </div>
                <div className="col-12">
                  <p className="text-center">
                    You will be redirected to PayFast for secure payment
              </p>
                </div>
              </div>
            </form>

          </div>
        </React.Fragment>
      );
    } else if (this.state.step > 2) {
      return (
        <React.Fragment>
          <div className="container voucher-pay-button">
            <div className="row">
              <div className="col-sm-12">
                <form
                  action="https://www.payfast.co.za/eng/process"
                  method="POST"
                  id="PayfastForm"
                >
                {/* <form
              id="PayfastForm"
              action="https://sandbox.payfast.co.za/eng/process"
               method="POST" 
             >  */}
                  <input type="hidden" name="merchant_id" value="14531448" />
                  <input type="hidden" name="merchant_key" value="qjy6tdfpb6v9r" />
                  {/* <input type="hidden" name="merchant_id" value="10015462" />
              <input type="hidden" name="merchant_key" value="o20qo74zh8bq7" /> */}
                  <input type="hidden" name="custom_str1" value={this.state.voucherIdentifier} />
                  <input
                    type="hidden"
                    name="return_url"
                    value="https://legacybalance.co.za/thank-you"
                  />
                  <input
                    type="hidden"
                    name="cancel_url"
                    value="https://legacybalance.co.za/cancel"
                  />
                  <input
                    type="hidden"
                    name="notify_url"
                    value="https://adminpanel.legacyspas.co.za/api/default"
                  />

                  <input
                    type="hidden"
                    name="name_first"
                    value={this.state.firstname}
                  />
                  <input
                    type="hidden"
                    name="name_last"
                    value={this.state.lastname}
                  />
                  <input
                    type="hidden"
                    name="email_address"
                    value={this.state.senderEmailAddress}
                  />
                  <input type="hidden" name="m_payment_id" value={this.state.voucherIdentifier} />
                  <input type="hidden" name="amount" value={this.state.value} />
                  <input
                    type="hidden"
                    name="item_name"
                    value="Spa Voucher"
                  />
                  <input type="hidden" name="item_description" value="Spa Voucher" />
                  <input type="hidden" name="email_confirmation" value="1" />
                  <input
                    type="hidden"
                    name="confirmation_address"
                    value="margarita@legacybalance.co.za"
                  />
                  <input type="hidden" name="payment_method" value="cc" />
                  {
                    (this.state.emailSent)?
                    // <button type="submit" className="btn btn-success">Pay Now</button>
                    <p>Redirecting to PayFast...</p>
                    :
                    <p>Loading, please wait...</p>
                  }
                </form>
              </div>
            </div>
          </div>
          {/* {document.getElementById("PayfastForm").submit()} */}
        </React.Fragment>
      );
    }
  };

  setActiveStyle = () => {
    if (this.state.step === 0) {
      return (
        <React.Fragment>
          <ul className="list-unstyled multi-steps">
            <li className="is-active">Choose Design</li>
            <li>Create</li>
            <li>Accept</li>
            <li>Pay</li>
          </ul>
        </React.Fragment>
      );
    } else if (this.state.step === 1) {
      return (
        <React.Fragment>
          <ul className="list-unstyled multi-steps">
            <li>Choose Design</li>
            <li className="is-active">Create</li>
            <li>Accept</li>
            <li>Pay</li>
          </ul>
        </React.Fragment>
      );
    } else if (this.state.step === 2) {
      return (
        <React.Fragment>
          <ul className="list-unstyled multi-steps">
            <li>Choose Design</li>
            <li>Create</li>
            <li className="is-active">Accept</li>
            <li>Pay</li>
          </ul>
        </React.Fragment>
      );
    } else if (this.state.step === 3) {
      return (
        <React.Fragment>
          <ul className="list-unstyled multi-steps">
            <li>Choose Design</li>
            <li>Create</li>
            <li>Accept</li>
            <li className="is-active">Pay</li>
          </ul>
        </React.Fragment>
      );
    }
  };

  setDate = date => {
    this.setState({
      ...this.state,
      dateTimeSelected: date._d
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="voucher">
          <h1>Buy a Voucher</h1>
          {this.setActiveStyle()}
          {this.returnStep()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);
