import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./multislider.css";
import Partner from "../Partner/Partner";
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    Modal, ModalHeader, ModalBody, 
    ModalFooter
  } from 'reactstrap';
  import Style from 'style-it';

class MultiSlider extends Component{

constructor(props){
    super(props);
    this.state = {
        images: [
            {
                logoUrl: ""
            }
        ],
        modal: false,
        backgroundColor: "",
        hoverColor: "",
        partnerImages: [
            {
                logoUrl: ""
            }
        ]
    }
}

componentDidMount(){
    // Product data fetch
    // fetch("https://legacybalance.co.za/api/product")
    // .then(response => response.json())
    // .then(data => {
    //     return data;
    // })
    // .then(data => {
    //     // console.log("MULTI: ", data);
    //     this.setState({
    //         ...this.state,
    //         images: data
    //     })
    // });


    let productData = [];
    fetch("https://legacybalance.co.za/api/product/")
    .then(response => response.json())
    .then(data => {
        console.log("PRODUCT: ", data);
            for (var i=0; i < data.length; i++) {
                if(data[i] !== null && data[i] !== undefined){
                    if (data[i].validSpaList.includes(this.props.spaName)) {
                        productData.push(data[i]);
                        //return productData;
                    }
                    // else{
                    //     productData = null;
                    // }
                }
            }
        }).then((data) => {
            if(productData !== null){
                console.log("NAME: ", data);
                this.setState({...this.state,
                    images: productData
            });
            }else{
                console.log("NAME: ", "Empty");
                this.setState({...this.state,
                    images: "Empty"
            });
            }
        })




    // Partners Data Fetch
    fetch("https://legacybalance.co.za/api/partner")
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .then(data => {
        // console.log("MULTI: ", data);
        this.setState({
            ...this.state,
            partnerImages: data
        })
    });
    fetch(`https://legacybalance.co.za/api/spas/${this.props.spaName}`)
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .then(data => {
        this.setState({
            ...this.state,
            backgroundColor: data[0].backgroundColor,
            hoverColor: data[0].hoverColor
        })
    });


    // if(this.state === nextState){
       // console.log("UPDATE NOT: ", "NOT THE SAME!!!!!!!!!");
        let partnerData;
        fetch("https://legacybalance.co.za/api/partner/")
        .then(response => response.json())
        .then(data => {
            //console.log("PARTNER: ", data);           
                for (var i=0; i < data.length; i++) {
                    if (data[i].validSpaList.includes(this.props.spaName)) {
                        if(data[i] !== null && data[i] !== undefined && partnerData !== null && partnerData !== undefined){
                        partnerData.push(data[i]);
                        }
                       // return partnerData;
                    }
                    // else{
                    //     partnerData = null;
                    // }
                }
            }).then((data) => {
                if(partnerData !== null){
                    //console.log("NAME: ", data);
                    this.setState({...this.state,
                        partnerImages: partnerData
                });
                }else{
                    //console.log("NAME: ", "Empty");
                    this.setState({...this.state,
                        partnerImages: "Empty"
                });
                }
            })
}

toggle = () => {
    this.setState({
        ...this.state,
        modal: !this.state.modal
    });
}

render(){
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

return(
    <div className="spa-products">
        <h2>Our Partners &amp; Products</h2>
        {/* <h2>Our Products</h2> */}
    <Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={1000}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
    {/* Product Cards Start */}
    {(this.state.images !== null && this.state.images !== undefined && this.state.images !== "Empty")?    
    this.state.images.map((item, index) => {
        return(
            <div class="product-image-wrapper" key={index}>
            <img src={item.logoUrl} alt={item.houseName}/>
        </div>
        );
    })
:
<React.Fragment></React.Fragment>
}
    {/* Product Cards End */}

    {/* Partner Cards Start */}
    {
        (this.state.partnerImages !== null && this.state.partnerImages !== undefined && this.state.partnerImages !== "Empty")?     
     this.state.partnerImages.map((item, index) => {
                        return (
             <div className="product-image-wrapper" key={index + 150}>
                 <Card>
                     <CardImg top width="100%" src={item.logoUrl} alt={item.name} />
                     <CardBody style={{ paddingBottom: "-15px" }}>
                         <div style={{ width: "100%" }}>
                             <div className="spa-product-partner-card" style={{ padding: "0px", marginTop: "10px" }}>
                                <p>{item.name}</p> 
                             
                                 <Style>
                                     {`
                                    .dynamicButton{
                                        background-color: ${this.state.backgroundColor};
                                        border-color: ${this.state.backgroundColor};
                                    }
                                    .dynamicButton:hover {
                                        background-color: ${this.state.hoverColor};
                                        border-color: ${this.state.hoverColor};
                                        }
                                        `}
                                     <button className="btn btn-primary dynamicButton" onClick={this.toggle}>More Information</button>
                                 </Style>
                                 {/* <button className="btn btn-secondary" onClick={this.toggle}>More Information</button> */}
                             </div>
                         </div>
                         {/* <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText> */}
                     </CardBody>
                 </Card>
                 <Modal isOpen={this.state.modal} toggle={this.toggle} className="container">
                     <ModalHeader toggle={this.toggle}>{item.name}</ModalHeader>
                     <ModalBody>
                         <p>
                             {item.description}
                         </p>
                         <p>
                             {item.story}
                         </p>
                     </ModalBody>
                     <ModalFooter>
                         <Style>
                             {`
                         .dynamicButton{
                             background-color: ${this.state.backgroundColor};
                             border-color: ${this.state.backgroundColor};
                            }
                        .dynamicButton:hover {
                            background-color: ${this.state.hoverColor};
                            border-color: ${this.state.hoverColor};
                        }
                        `}
                             <button className="btn btn-primary dynamicButton" onClick={this.toggle}>Close</button>
                         </Style>
                         {/* <Button color="primary" onClick={this.toggle}>Close</Button> */}
                     </ModalFooter>
                 </Modal>
             </div>
         );
     })
     :
     <React.Fragment></React.Fragment>
     }
            {/* Partner Cards End */} 
</Carousel>
</div>
);
}
}

export default MultiSlider;