import * as types from "./actionTypes";

 function executeCheck(status) {
    //  console.log("AUTH_STATUS", status);
  return { type: types.AUTH_STATUS, isAuthenticated: status };
}

export function checkAuthStatus(status) {
  return dispatch => {
    return dispatch(executeCheck(status));
  };
}