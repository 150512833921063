import * as types from "./actionTypes";

export function doUpdateGeneralForm(editorVals) {
  return { type: types.TC_EDITOR_CHANGE, editorState: editorVals };
}

export function doGeneralForm(editorValues) {
  return dispatch => {
    return dispatch(doUpdateGeneralForm(editorValues));
  };
}

export function sendHtmlGeneralForm(editorVals) {
  return { type: types.TC_EDITOR_HTML, termsAndConditions: editorVals };
}

export function htmlGeneralForm(editorValues) {
  return dispatch => {
    return dispatch(sendHtmlGeneralForm(editorValues));
  };
}

function setInitialValsGeneralForm(editorVals) {
  // console.log("General Form Actions");
  return { type: types.TC_GET_VALUES, initialVals: editorVals };
}

export function getInitialValsGeneralForm() {
  return dispatch => {
    fetch("api/LegacyBalanceGeneralFields/1")
      .then(response => response.json())
      .then(data => dispatch(setInitialValsGeneralForm(data)));
    // console.log("FETCH_CALLED");
  };
}
