import React, {Component} from "react";

class Privacy extends Component{
    constructor(props){
        super(props);

        this.state = {
            initialState: ""
        }
    }

    render(){
        return(
            <div className="container">
                <br/>
                <h3 className="text-center">Legacy Balance Spas Privacy Policy</h3>
                <hr/>
                <p>This privacy policy has been compiled to better serve those who are concerned with how their Personal Information is being used online.</p>
                <p><strong>What personal information do we collect and when?</strong></p>
                <p>When ordering a voucher or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your purchase or experience. You may, however, visit our site anonymously.</p>
                <p><strong>How do we use your information?</strong></p>
                <p>We may use the information we collect from you when you make a purchase, sign up for our newsletter, respond to a survey or marketing communication, use certain other site features, in the following ways:</p>
                <ul>
                    <li style={{marginLeft: "20px"}}><strong>To quickly process your transaction.</strong>. Note: Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased gift voucher requested. The email address you provide for order processing, will only be used to send you information and updates pertaining to your order.
</li>
<li style={{marginLeft: "20px"}}>
    <strong>If you decide to opt-in to our mailing list, you will receive emails that may include company news, updates, related product or service information, and monthly promotions. </strong>
    . Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.

</li>
                </ul>
                <p><strong>How do we protect your information?</strong></p>
                <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or access your personal information.</p>
                <p><strong>We use regular computer programme Scanning.</strong></p>
                <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted. All transactions are processed through a gateway provider and are not stored or processed on our servers. A safe external payment gateway handles all CC transactions.</p>
                <p><strong>Third-party disclosure</strong></p>
                <p>We do not sell, trade, or otherwise transfer to outside parties your personal information. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. </p>
                <p><strong>Third-party links</strong></p>
                <p>We do not include or offer third-party products or services on our website.</p>
                <p><strong>Legacy Balance Spas</strong> conforms to the regulations as set out by the new Consumer Protection Act (CPA) and thereby is obliged to advise our clients of our Terms & Conditions of business.</p>
                <ul>
                <li style={{marginLeft: "20px"}}>
                Spa Etiquette: -All our guests are welcome to enjoy every aspect of our Spas.  Arrival time is a minimum of 15 minutes prior to your first booking. Please note that should you wish to arrive earlier and make use of the relaxation area and hydro pools you are most welcome. Guests are advised that our therapies run to a tight time schedule. Should you arrive later than your scheduled booking time, you may be required to have the duration of your treatment cut short, however full price will apply.
                    </li>
                    <li style={{marginLeft: "20px"}}>
                    Each Legacy Balance Spa is a sanctuary of peace and quiet. We aim to give you a very relaxing experience. For us to be able to achieve the best possible treatment, we require all mobile phones to be turned off at check in and not to be used whilst in the Spa.
                    </li>
                    <li style={{marginLeft: "20px"}}>
                    What to Wear: - Comfortable clothing and swimming costumes are a requirement as all public facilities are unisex excluding change rooms. Thongs will be provided for pedicures. We provide a locker, Spa robes, towels, slippers and disposable underwear during any body treatment.
                    </li>
                    <li style={{marginLeft: "20px"}}>
                    Age Requirements: - Each Spa offers a relaxing and harmonious environment for you to enjoy. Some treatments are not recommended for children. Children under the age of 13 must be accompanied by an adult at all times and are not permitted in any heated circuit.
                    </li>
                    <li style={{marginLeft: "20px"}}>
                    Cancellation Policy: - A booking cancellation fee of 50% will be charged on all bookings that are cancelled within 12 hours or less of the appointment time.

                    </li>
                    <li style={{marginLeft: "20px"}}>
                    Operating Hours: - Our operating hours vary with every property. please visit our website for more details.
                    </li>
                    <li style={{marginLeft: "20px"}}>
                    Product use: - Our Spas rely exclusively on the information provided by the manufacturers of the retail and professional products. Information provided is from the product testing done and, in some cases, clinical trials by the ingredient/product manufacturers. Kindly note that all treatments are carried out at your own risk. Legacy Balance Spas cannot be held liable/responsible for any loss or injury to person.
                    </li>
                </ul>
            </div>
        );
    }
}

export default Privacy;