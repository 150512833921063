import { EditorState } from "draft-js";

export default {
  stuff: "stuff initial values (Testing Value in Redux Store)",
  //editorState: EditorState.createEmpty(),
  // emailAddress: "",
  // telephoneNumber: "",
  // facebookPageUrl: "",
  // instagramPageUrl: "",
  // instagramUsername: "",
  // termsAndConditions: "",
  spaList: [
    {
      spaId: 1,
      editorState: EditorState.createEmpty(),
      receptionEmailAddress: "",
      name:"",
      physicalAddress: "",
      telephoneNumber: "",
      cellNumber: "",
      story:"",
      hotelUrl: " ",
      termsAndConditions: "<p>Test</p>",
      promotion: {},
      priceLists: {0: []}
    }
  ],
  selectedSpaId: null
};
