import React, { Component } from "react";
import AllSpasGMap from './AllSpasGMap';

export default class AllSpasMap extends Component {
constructor(){
  super();
  this.state = {
    name: "",
    lng: 28.369634,
    lat: -26.498659,
    locations: [],
    allInfo: {}
  }
}

componentDidMount(){
//   this.setState({
//     ...this.state,
//     name: this.props.match.params.id
//   })

  fetch(`https://legacybalance.co.za/api/spas/`)
  .then(response => response.json())
  .then(data => {
    return data;
  })
  .then(data => {
    let locs = [];

    // const getLocs = (item, index) => {
    //   locs.push({latitude: item.latitude, longitude: item.longitude});
    //   this.setState({
    //           ...this.state,
    //           lat: item.latitude,
    //           lng: item.longitude,
    //           allInfo: data
    //         });    
    // }

    //   if(item.name === this.props.match.params.id){
    //     this.setState({
    //       ...this.state,
    //       lat: item.latitude,
    //       lng: item.longitude,
    //       allInfo: data
    //     });
    //   }    

    data.forEach((item, index) => {
      locs.push({latitude: item.latitude, longitude: item.longitude});
      this.setState({
              ...this.state,
              lat: item.latitude,
              lng: item.longitude,
              allInfo: data
            });    
    });
    this.setState({
      ...this.state,
      locations: locs
    })
  });
}

    render() {
     // let initialCenter = { lat: this.state.lat, lng: this.state.lng};
      return (
        <React.Fragment>
              <div>
                <div className="map-wrapper">
                  {(this.state.locations.length > 0)?<AllSpasGMap 
                  //initialCenter={initialCenter}
                  locs={this.state.locations}
                  //name={this.state.name}
                  allInfo={this.state.allInfo}
                  />:<React.Fragment></React.Fragment>}
                  
                </div>
              </div>         
        </React.Fragment>
      );
    }
  }