import * as types from "./actionTypes";

function setInitialValsSpaList(xkzcbvkjbafd) {
    // console.log("32");
    // console.log("33", xkzcbvkjbafd);
    // console.log("34", xkzcbvkjbafd.logoId);
    return {type: types.Spa_PAGE_GET_VALS, initialVals: xkzcbvkjbafd};
    //return { type: types.SPA_LIST_GET_VALUES, spaListGetVals: xkzcbvkjbafd };
}

export function getInitialValsSpaList(name) {
    return dispatch => {
        fetch(`api/Spas/${name}`)
            .then(response => {
            //   console.log(response);
                return response.json();
            })
            .then(data =>

                dispatch(setInitialValsSpaList(data))
            );
    };
}