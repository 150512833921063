import React, { Component } from "react";
import { connect } from "react-redux";
import "./spa.css";
import { bindActionCreators } from "redux";
import Branding from "../../components/Spas/Branding/Branding";
import FeaturedImages from "../../components/Spas/FeaturedImages/FeaturedImages";
import FeaturedVideo from "../../components/Spas/FeaturedVideo/FeaturedVideo";
import Info from "../../components/Spas/Info/Info";
import Promotion from "../../components/Spas/Promotion/Promotion";

import Packages from "../../components/Spas/SpaPackages/Packages";
import CrossListings from "../../components/Spas/CrossListings/CrossListings";
import MapBanner from "../../components/MapBanner/MapBanner";
import VidGallery from "../../components/Spas/VidGallery/VidGallery";
import "../../components/Spas/VidGallery/VidGallery.css";
import SpaCard from "../../components/SpaCard/SpaCard";
import * as spaAction from "../../actions/spaPageActions";
import ShareButtons from "../../components/ShareButtons/ShareButtons";
import VoucherBanner from "../../components/VoucherBanner/VoucherBanner";
import * as spaListActions from "../../actions/spaListActions";
import MultiSlider from "../../components/Slider/MultiSlider";
import { relativeTimeThreshold } from "moment";
import Partner from "../../components/Partner/Partner";
import FormContact from "../../components/FormContact/FormContact";

class Spa extends Component {

  constructor(props){
    super(props);
    this.state = {
      logoUrl: "",
      featuredImageUrl: "",
      address: "Some",
      vidUrl: "",
      phoneNumber: "",
      coverImage: "",
      allInformation: [],
      crossListings: "",
      hasRun: false,
      id: "",
      shouldBeFullWidth: true,
      windowWidth: window.innerWidth,
      story: ""
    }
  }

  componentDidMount(){
    
    this.getInitialValsSpaList(this.props.match.params.id);
    
    fetch(`api/Spas/${this.props.match.params.id}`)
    .then(response => {
      return response.json();
    })
    .then(data => {
      // console.log("INFO_RECEIVED: ", data[0]);
      this.setState({
        ...this.state,
        phoneNumber: data[0].telephoneNumber,
        address: data[0].physicalAddress,
        cellNumber: data[0].cellNumber,
        emailAddress: data[0].receptionEmailAddress,
        vidUrl: data[0].featuredVideo.url,
        coverImage: data[0].coverImage.url,
        allInformation: data,
        crossListings: this.listing(),
        id: data[0].spaId
      });
    })
    this.props.spaPageActions.getInitialValsSpaList(this.props.match.params.id);
    this.props.spaListActions.getInitialValsSpaList();

    // this.setState({
    //   //...this.state,
    //   crossListings: this.listing()
    //     });

    window.addEventListener('resize', this.getWidth);
    this.getWidth();
  }

  componentDidUpdate(prevProps, prevState){
     //console.log("CROSSLISTINGDSS: ", prevState);
      this.updateVals(this.props.match.params.id, prevState.logoUrl);
      // if(this.listing() === prevState.crossListings){
      if(this.props.allSpaInformation.spaList.length > 1 && !this.state.hasRun){
        this.setState({
          ...this.state,
          crossListings: this.listing(),
          hasRun: true
        })
      }
    
  }
  
  listing() {
    // console.log("CROSS LISTING LENGTH: ", this.props.allSpaInformation);
    if (this.props.allSpaInformation.spaList.length > 1) {
      return(
        <React.Fragment>
 {this.props.allSpaInformation.spaList.map((item, index) => {
      
return(
  (item.name !== this.props.match.params.id)? 
    <SpaCard
  key={item.spaId}
  logoUrl={item.logo.url}
  coverUrl={item.coverImage.url}
address={item.physicalAddress}
telephone={item.telephoneNumber}
cellNumber={item.cellNumber}
emailAddress={item.receptionEmailAddress}
cssLight={item.cssLight}
MoreInfo={item}
Index={index}
spaName={item.name}
allSpaInfo={item}
/>
  :
  <React.Fragment>

  </React.Fragment>
  
);                  
 })
 }
                </React.Fragment>
 )
}



              // );
    //     }else{
    //       return(
    //         <React.Fragment>
    //           <h1>Not in list</h1>
    //         </React.Fragment>
    //       );
    //     }
    //   });
    //     </React.Fragment>
    //   );
     
    // } else {
    //   return (
    //     <React.Fragment>
    //       <h1>No ELEMENTS</h1>
    //     </React.Fragment>
    //   );
    // }
  };

  async getInitialValsSpaList(name) {
    
       await fetch(`api/Spas/${name}`)
            .then(response => {
              console.log(response);
                return response.json();
            })
            .then(data => {          
              this.setState(
                {
                  ...this.state,
                  logoUrl: data[0].logo.url,
                  featuredImageUrl: data[0].featuredImage.url,
                  address: data[0].physicalAddress,
                  vidUrl: data[0].featuredVideo.url,
                  allInformation: data,
                  id: data[0].spaId,
                  story: data[0].story
                }
              );
    }
            );
          
    }

   async updateVals(name, prevState) {
    
     await fetch(`api/Spas/${name}`)
          .then(response => {
           // console.log(response);
              return response.json();
          })
          .then(data => {      
            if(data[0].logo.url !== prevState){
              this.setState(
                {
                  ...this.state,
                  logoUrl: data[0].logo.url,
                  featuredImageUrl: data[0].featuredImage.url,
                  address: data[0].physicalAddress,
                  vidUrl: data[0].featuredVideo.url,
                  allInformation: data,
                  story: data[0].story
                }
              );
            }
  }
          );

          // if(prevState === this.listing()){
          //   this.setState({
          //     crossListings: this.listing()
          //   })
          // }
       
  }

  imageSetBuilder(vids){
    return [{
      thumbnail:"",
      original: "",
      // embedUrl: 'https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0',
      embedUrl: vids,
      description: 'Render custom slides within the gallery',
      //renderItem: this._renderVideo.bind(this)
    }];
  }

  showShareButtons = () => {

    if(this.state.windowWidth > 1250){
      this.setState(
        {
          ...this.state,
          shouldBeFullWidth: false
        }
        );
      }else{
        this.setState({
          ...this.state,
          shouldBeFullWidth: true
        })
      }
  }

  getWidth = () => {
    this.setState({
      ...this.state,
      windowWidth: window.innerWidth
    })
    this.showShareButtons();
  }

  getTempPromo(tempSpaName) {
    return(
      (tempSpaName != "Leonardo-Spa-Sandton")? 
      <React.Fragment>
        <div key={'featured-promo-image-one'} className="temp-promo">
          <img src="https://legacybalance.co.za/FeaturedImages/March-2022--DaVinci-Digital-Promotinal-Artwork.jpg" alt="March 2023 Promotion" width={'100%'} className="mx-auto d-block"/>
        </div>
      </React.Fragment>
      :
      <React.Fragment></React.Fragment>
    );
  }

  render() {
    return <React.Fragment> 

{/* <div key={'featured-promo-image-one'} className="temp-promo">
          <img src="https://legacybalance.co.za/FeaturedImages/March-2022--DaVinci-Digital-Promotinal-Artwork.jpg" alt="March 2023 Promotion" width={'100%'} className="mx-auto d-block"/>
        </div> */}
        {/* {console.log("TEMPPROMO: ", this.props.match.params.id)} */}
        {/* {this.getTempPromo(this.props.match.params.id)} */}
        <Branding 
        FeaturedImage={this.state.featuredImageUrl}
        Logo={this.state.logoUrl}
        AdditionalInfo={this.props.spaInformation.spaPageReducer.spaList[0]}
        id={this.props.match.params.id}
        name={this.props.spaInformation.spaPageReducer.spaList[0].name}
        />
        <div className="spa-welcome" dangerouslySetInnerHTML={{__html: this.state.story}} />

        <div className="row text-center bottom-space">
          {/* <div className="col col-2"></div> */}
          <div className="col col-12">
            <FormContact FormType="Packages" spaName={this.props.spaInformation.spaPageReducer.spaList[0].name}/>
          </div>
          {/* <div className="col col-2"></div> */}
        </div>

        <div className="spa-page">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Info
            address={this.state.address}
            phoneNumber={this.state.phoneNumber}
            cellNumber={this.state.cellNumber}
            emailAddress={this.state.emailAddress}
            information={this.state.coverImage}
            allSpaInfo={this.props.spaInformation.spaPageReducer.spaList[0]}
            />
            {(!this.state.shouldBeFullWidth)?  <ShareButtons
              name={this.props.spaInformation.spaPageReducer.spaList[0].name}
            /> : <React.Fragment></React.Fragment>}
          </div>
          <div className="col-12 col-lg-6">
            {/* {console.log("TEST_PARAMS", this.props.spaInformation)} */}
            <Promotion
              url={this.props.spaInformation.spaPageReducer.spaList[0]}
              allSpaInfo = {this.props.spaInformation.spaPageReducer.spaList[0]}
            />
          </div>
          {(this.state.shouldBeFullWidth)?  <div className="col-12 col-lg-12">
            
            <ShareButtons
              name={this.props.spaInformation.spaPageReducer.spaList[0].name}
            />
          </div> : <React.Fragment></React.Fragment>}
         {(this.props.spaInformation.spaPageReducer.spaList[0].name == "Leonardo-Spa-Sandton")?<div style={{padding: '25px'}}><img style={{ width: '100%'}} src="https://legacybalance.co.za/leospa_offer.jpg"/></div>:console.log('Not leo spa')}
        </div>
        </div>
       
        <FeaturedImages id={this.props.match.params.id}/>
        <MultiSlider
        spaName={this.props.match.params.id}/>
        <Packages TypeOfContact={this.props.match.params.id}/>
        {/* <Partner
        spaName={this.props.match.params.id
        }
        /> */}
        <VoucherBanner />
        {/* {console.log("CROSS LISTING CHECK: ", this.state.crossListings) } */}
        <div className="container-fluid">
          <h2>Other Legacy Spas</h2>
        <div className="row">
        {this.state.crossListings}
        </div>
        </div>
        {/* {this.listing()} */}
        
        {/* <CrossListings
        address={this.state.address}      
        allSpaInfo={this.props.allSpaInformation}
        selectedSpa={this.props.match.params.id}
        /> */}
        
        <MapBanner/>      
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  
  return {
    spaInformation: state,
    allSpaInformation: state.spaListReducer
    
  };
};

function mapDispatchToProps(dispatch) {
 return{
  spaPageActions: bindActionCreators(spaAction, dispatch),
  spaListActions: bindActionCreators(spaListActions, dispatch)
 }
}

// export default Spa;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spa);
