import React from 'react';
import ReactDOM from 'react-dom';
import {Editor, EditorState, RichUtils} from 'draft-js';
import "../../../../../node_modules/draft-js/dist/Draft.css";

class MyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.onChange = (editorState) => this.setState({editorState});
  }

  _onBoldClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
  }

  render() {
    return (
      <div>
        <button onClick={this._onBoldClick.bind(this)}>Bold</button>
          <Editor editorState={this.state.editorState} onChange={this.onChange}/>
      </div>
    );
  }
}
export default MyEditor;
// ReactDOM.render(
//   <MyEditor />,
//   document.getElementById('container')
// );