import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Editor } from "react-draft-wysiwyg";
//import "../../Containers/GeneralAdmin/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as Yup from "yup";

const GeneralForm = props => {
  return (
    <React.Fragment>
      {/* <Formik
        initialValues={props.initialValues}
        validate={props.values}
        onSubmit={props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>General Information</h3>
            <hr />

            <div className="form-group">
              <label>Email Address</label>
              <Field
                type="emailAddress"
                name="emailAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailAddress}
                className="form-control"
              />
              {errors.emailAddress && touched.emailAddress && errors.emailAddress}
              <ErrorMessage
                name="emailAddress"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label>Telepone Number</label>
              <input
                type="text"
                name="telephoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telephoneNumber}
                className="form-control"
              />
              {errors.telephoneNumber &&
                touched.telephoneNumber &&
                errors.telephoneNumber}
            </div>
            <div className="form-group">
              <label>Facebook Page Url</label>
              <input
                type="text"
                name="facebookPageUrl"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.facebookPageUrl}
                className="form-control"
              />
              {errors.facebookPageUrl &&
                touched.facebookPageUrl &&
                errors.facebookPageUrl}
            </div>
            <div className="form-group">
              <label>Instagram Page Url</label>
              <input
                type="text"
                name="instagramPageUrl"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.instagramPageUrl}
                className="form-control"
              />
              {errors.instagramPageUrl &&
                touched.instagramPageUrl &&
                errors.instagramPageUrl}
            </div>
            <div className="form-group">
              <label>Instagram Username</label>
              <input
                type="text"
                name="instagramUsername"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.instagramUsername}
                className="form-control"
              />
              {errors.instagramUsername &&
                touched.instagramUsername &&
                errors.instagramUsername}
            </div>
            <div className="form-goup">
              <label>Terms and Conditions</label>
              <Editor
                editorState={props.editorStateInitial}
                onEditorStateChange={editorState => {
                  props.editorChange(editorState);
                }}
                wrapperClassName="form-group"
                editorClassName="form-control"
              />
            </div>
            <div className="form-group text-right">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik> */}

      <Formik
        // initialValues={{
        //   firstName: "",
        //   lastName: "",
        //   emailAddress: "",
        //   password: "",
        //   confirmPassword: ""
        // }}
        enableReinitialize
        initialValues={props.initialValues}
        validationSchema={Yup.object().shape({
          telephoneNumber: Yup.string().required(
            "Telephone number is required"
          ),
          facebookPageUrl: Yup.string().required(
            "Facebook Page Url is required"
          ),
          instagramPageUrl: Yup.string().required(
            "Instagram Page Url is required"
          ),
          instagramUsername: Yup.string().required(
            "Instagram Username is required"
          ),
          emailAddress: Yup.string()
            .email("Email is invalid")
            .required("Email is required")
        })}
        onSubmit={props.onSubmit}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="emailAddress">Email</label>
              <Field
                name="emailAddress"
                type="text"
                className={
                  "form-control" +
                  (errors.emailAddress && touched.emailAddress
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="emailAddress"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="telephoneNumber">Telephone Number</label>
              <Field
                name="telephoneNumber"
                type="text"
                className={
                  "form-control" +
                  (errors.telephoneNumber && touched.telephoneNumber
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="telephoneNumber"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="facebookPageUrl">facebookPageUrl</label>
              <Field
                name="facebookPageUrl"
                type="text"
                className={
                  "form-control" +
                  (errors.facebookPageUrl && touched.facebookPageUrl
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="facebookPageUrl"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="instagramPageUrl">Instagram Page Url</label>
              <Field
                name="instagramPageUrl"
                type="text"
                className={
                  "form-control" +
                  (errors.instagramPageUrl && touched.instagramPageUrl
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="instagramPageUrl"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="instagramUsername">Instagram Username</label>
              <Field
                name="instagramUsername"
                type="text"
                className={
                  "form-control" +
                  (errors.instagramUsername && touched.instagramUsername
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="instagramUsername"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-goup">
              <label>Terms and Conditions</label>
              <Editor
                editorState={props.editorStateInitial}
                onEditorStateChange={editorState => {
                  props.editorChange(editorState);
                }}
                wrapperClassName="form-group"
                editorClassName="form-control"
              />
            </div>
            <div className="form-group text-right">
              <button
                type="submit"
                className="btn btn-primary mr-2"
                disabled={isSubmitting}
              >
                Submit
              </button>
              <button type="reset" className="btn btn-secondary">
                Reset
              </button>
            </div>
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default GeneralForm;
