import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import { Link } from "react-router-dom";
import LoginMenu  from "../api-authorization/LoginMenu";
import "./NavMenu.css";
import StuffList from "../TemplateStuff/StuffList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authAction from "../../actions/authAction";
import AvailableSpas from "../Spas/AvailableSpas";

class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  menuItemsToShow(){
    const showAdmin = this.props.isAuth;

    if(showAdmin){
      return (
        <React.Fragment>
          <AvailableSpas />
          <NavItem>
            <NavLink tag={Link} to="/">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/general">
              General Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/spas">
              Spa Admin
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink tag={Link} to="/vouchers">
              Vouchers
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink tag={Link} to="/map">
              Map
            </NavLink>
          </NavItem>
        </React.Fragment>
      );
    }else{
      return (
        <React.Fragment>
          <NavItem>
                  <NavLink tag={Link} to="/">
                    Legacy Spas
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <AvailableSpas />
                </NavItem> */}
                <NavItem>
                  <NavLink tag={Link} to="/vouchers">
                    Gift Vouchers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <a tag={Link} href="http://blog.legacybalance.co.za" className="nav-link">
                    Blog
                  </a>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/all-spas-map">
                    Map
                  </NavLink>
                </NavItem>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow"
          light
        >
          <NavbarBrand tag={Link} to="/">
            {<img src="https://legacybalance.co.za/Logos/Legacy-Balance-Logo.png" />}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!this.state.collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              {this.menuItemsToShow()}
              {/* <LoginMenu></LoginMenu> */}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);
  return {
      isAuth: state.AuthReducer.isAuthenticated
  };
};

export default connect(
  mapStateToProps
)(NavMenu);