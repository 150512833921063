import React from "react";
import "./ShareButtons.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from 'react-share';

const ShareButtons = (props) => {
  const shareUrl = `https://legacyspas.co.za/${props.name}`;
  const title = `Legacy Spas ${props.name}`;
  return (
    <React.Fragment>
      <div className="share-buttons">
        {/* Comment */}
        <h2>Connect with us</h2>
        <p>Click on the icons below to share this website with friends or family.</p>
        <div className="social-container">

          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="social-container__social-icons__share-button">
            <FacebookIcon
              size={64}
              round />
          </FacebookShareButton>



          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <TwitterIcon
              size={64}
              round />
          </TwitterShareButton>




          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <TelegramIcon size={64} round />
          </TelegramShareButton>




          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="social-container__social-icons__share-button">
            <WhatsappIcon size={64} round />
          </WhatsappShareButton>





          <LinkedinShareButton
            url={shareUrl}
            windowWidth={750}
            windowHeight={600}
            className="social-container__social-icons__share-button">
            <LinkedinIcon
              size={64}
              round />
          </LinkedinShareButton>



          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="social-container__social-icons__share-button">
            <RedditIcon
              size={64}
              round />
          </RedditShareButton>



          <TumblrShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="social-container__social-icons__share-button">
            <TumblrIcon
              size={64}
              round />
          </TumblrShareButton>



          <LivejournalShareButton
            url={shareUrl}
            title={title}
            description={shareUrl}
            className="social-container__social-icons__share-button"
          >
            <LivejournalIcon size={64} round />
          </LivejournalShareButton>



          <MailruShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <MailruIcon
              size={64}
              round />
          </MailruShareButton>



          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="social-container__social-icons__share-button">
            <EmailIcon
              size={64}
              round />
          </EmailShareButton>


          <ViberShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <ViberIcon
              size={64}
              round />
          </ViberShareButton>



          <WorkplaceShareButton
            url={shareUrl}
            quote={title}
            className="social-container__social-icons__share-button">
            <WorkplaceIcon
              size={64}
              round />
          </WorkplaceShareButton>



          <LineShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <LineIcon
              size={64}
              round />
          </LineShareButton>



          <PocketShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <PocketIcon
              size={64}
              round />
          </PocketShareButton>


          <InstapaperShareButton
            url={shareUrl}
            title={title}
            className="social-container__social-icons__share-button">
            <InstapaperIcon
              size={64}
              round />
          </InstapaperShareButton>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShareButtons;