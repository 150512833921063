import React from "react";
import "./FeaturedImages.css";
import MasonryGallery from '../../MasonryGallery/MasonryGallery';
    
const FeaturedImages = (props) => {
return(
<React.Fragment>
    <MasonryGallery id={props.id}/> 
</React.Fragment>
);
}
    
export default FeaturedImages;