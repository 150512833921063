import React, {Component} from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import InfoWindowContents from "./InfoWindow";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProductSliderMap from "../ProductSlider/ProductSliderMap";
import ReactDOMServer from 'react-dom/server';

const mapStyles = {
    width: '98%',
    height: '100%'
  };

class AllSpasGMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: this.props.locs,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      selectedInfoWindow: -1
    };
  }

  onMarkerClick = (props, marker, e) =>{
    // if(this.state.showingInfoWindow){
    //   this.setState({
    //     ...this.state,
    //     showingInfoWindow: false,
    //     activeMarker: null
    //   })
    // }
    // console.log("MARKER: ", marker);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      selectedInfoWindow: marker.id
    });
  }
    
  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      let singleInfo = this.props.allInfo[index];
    //   console.log("LOCATIONS: ", singleInfo);
      return (
        <Marker
          key={index}
          id={index}
          //label={singleInfo.name}
          position={{
            lat: store.latitude,
            lng: store.longitude
          }}
          onClick={this.onMarkerClick}
          //name={`${singleInfo.name} Current location`}
        />
      );
    });
  };

  createMarkup = (markup) => { return {__html: markup}; };

  displayInfoWindows = () => {
    return this.state.stores.map((store, index) => {
      let singleInfo = this.props.allInfo[index];
    //   console.log("LOCATIONS: ", singleInfo);
      return (
        <InfoWindow
        key={`infowindow ${index}`}
        allInfo={this.props.allInfo}
        allSpaInfo={this.props.allInfo}
        marker={this.state.activeMarker}
        visible={this.state.showingInfoWindow && this.state.selectedInfoWindow === index}>
          {/* <div style={{width: '100%'}}> */}
            {/* {console.log("INFO: ", singleInfo.coverImage.url)}
            {console.log("INFO: ", this.props.allInfo.map(function(e) { return e.name; }).indexOf(this.props.name))}
            {console.log(`INFO: ${this.props.name}`, this.props.allInfo.indexOf(this.props.name))} */}
          <InfoWindowContents
            address={singleInfo.physicalAddress}
            phoneNumber={singleInfo.telephoneNumber}
            cellNumber={singleInfo.cellNumber}
            emailAddress={singleInfo.receptionEmailAddress}
            information={singleInfo.coverImage.url}
            allSpaInfo={singleInfo}
            coverUrl={singleInfo.coverImage.url}
            logoUrl={singleInfo.logo.url}
            />
      </InfoWindow>
      );
    });
  };



  render() {
    return (
      <Map
        google={this.props.google}
        onClick={this.onMapClicked}
        initialCenter={{lat: -26.2558132, lng: 27.4824153}}
        zoom={7}
        style={mapStyles}
      >
        {this.displayMarkers()}
        {this.displayInfoWindows()}
      </Map>
    );
  }
}

  export default GoogleApiWrapper({
    apiKey: 'AIzaSyBpseAcCQgbI1wvNQXvRH-fLkioyNjJ1yk'
  })(AllSpasGMap);