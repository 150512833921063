import initialState from "./initialState";
import { FETCH_STUFF, RECEIVE_STUFF } from "../actions/actionTypes";

export default function stuff(state = initialState.stuff, action) {
  let newState;
  switch (action.type) {
    case FETCH_STUFF:
      //console.log("FETCH_STUFF Action");
      return action;
    case RECEIVE_STUFF:
      if (action.stuff != undefined && action.stuff !== null) {
        newState = action.stuff;
      } else {
        newState = state.stuff;
      }
      //console.log("RECEIVE_STUFF Action");
      return newState;
    default:
      return state;
  }
}
