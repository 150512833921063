import React, {Component} from "react";

class VoucherTerms extends Component{
    constructor(props){
        super(props);

        this.state = {
            initialState: ""
        }
    }

    render() {
        return (
            <div className="container">
                <br />
                <h3 className="text-center">Legacy Balance Online Voucher Terms and Conditions</h3>
                <hr />
                <p><strong>Recipient of a Gift Voucher</strong></p>
                <p>As per the consumer protection act, Effective 01 Nov 2009 - Gift Voucher are valid for a period of 36 months (3 years) from the date of issue (expiry date is on the Voucher).</p>
                <p>Voucher cannot be exchanged in whole or part for cash.</p>
                <p>All vouchers are redeemable for treatments only, unless at the discretion of the spa, they allow you to purchase products with the voucher.</p>
                <p>Bookings can be postponed (within the validity period) but cancelled only in accordance with the cancellation policy of the spa you are booked with. It will be appreciated that online voucher is in the hands of the participating spas in this regard in order to follow cancellation policy. </p>
                <p>The voucher is not transferable unless the person in question is in possession of the original voucher and the voucher is verified to be a valid voucher. (Not already redeemed by any other Legacy Balance Spa)</p>
                <p>Please send list of online vouchers redeemed on a monthly basis.</p>
                <p>Vouchers are subject to the terms and conditions of the participating spas however the validity remains 3 years from date of purchase.</p>
                <p>Pre-booking is essential and subject to availability.</p>
                <p><strong>Email delivery of vouchers:</strong></p>
                <p>When purchasing a voucher from Legacy Balance website, it is up to the purchaser to provide the correct details with regards to the information of the person receiving the voucher. The purchaser will not hold Legacy Balance or its employees liable for email vouchers not delivered, due to the incorrect information supplied by themselves. </p>
                <p>The purchaser will be able to be resend the voucher through with a request via email to <a href="mailto: margarita@legacybalance.co.za">margarita@legacybalance.co.za</a> should the voucher purchase was successful.</p>
            </div>
        );
    }
}

export default VoucherTerms;