import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as generalFormActions from "../../actions/generalFormActions";
import GeneralForm from "../../components/generalForm/generalForm";
import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import * as Yup from "yup";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as stuffActions from "../actions/stuffActions";
// import PropTypes from "prop-types";
// import React from "react";

// class suffList extends React.Component {

class General extends Component {
  static displayName = General.name;

  componentDidMount() {
    this.props.editorAction.getInitialValsGeneralForm();
  }

  doLogin(login, password) {
    // console.log(login, password);
  }

  onSubmit(login, password) {
    this.doLogin(login, password);
  }

  async getDataTest(data) {
    const dataWithId = {
      ...data,
      LegacyBalanceGeneralFieldsId: 1
    };
    // console.log(dataWithId);
    const dataFetchTest = await fetch("api/LegacyBalanceGeneralFields/1", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(dataWithId)
    });

    return dataFetchTest;
  }

  render() {
    const editorChange = editorVals => {
      this.props.editorAction.doGeneralForm(editorVals);
      let val = draftToHtml(
          convertToRaw(this.props.editorState.getCurrentContent())
      );
      this.props.editorAction.htmlGeneralForm(val);
    };

    const initialValues = {
      emailAddress: this.props.emailAddress,
      telephoneNumber: this.props.telephoneNumber,
      facebookPageUrl: this.props.facebookPageUrl,
      instagramPageUrl: this.props.instagramPageUrl,
      instagramUsername: this.props.instagramUsername,
      termsAndConditions: this.props.termsAndConditions,
    };

    const onSubmit = (values, { setSubmitting }) => {
      setTimeout(() => {
        values.termsAndConditions = this.props.termsAndConditions;
        const dataFetchTest = this.getDataTest(values);
        dataFetchTest.then(console.log(dataFetchTest));

        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }, 400);
    };

    return (
      <div id="div" className="container">
        <GeneralForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          editorChange={editorChange}
          editorStateInitial={this.props.editorState}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editorState: state.generalForm.editorState,
    termsAndConditions: state.generalForm.termsAndConditions,
    emailAddress: state.generalForm.emailAddress,
    telephoneNumber: state.generalForm.telephoneNumber,
    facebookPageUrl: state.generalForm.facebookPageUrl,
    instagramPageUrl: state.generalForm.instagramPageUrl,
    instagramUsername: state.generalForm.instagramUsername
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editorAction: bindActionCreators(generalFormActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General);
