import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authAction from "../../actions/authAction";

class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    // componentDidMount() {
    //     this._subscription = authService.subscribe(() => this.populateState());
    //     this.populateState();
    // }

    // componentWillUnmount() {
    //     authService.unsubscribe(this._subscription);
    // }

    async populateState() {
        // const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        // this.setState({
        //     isAuthenticated,
        //     userName: user && user.name
        // });
        // this.props.checkAuthStatus.checkAuthStatus(isAuthenticated);
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.isAuthenticated
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      checkAuthStatus: bindActionCreators(authAction, dispatch)
    };
  };
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginMenu);