import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Editor } from "react-draft-wysiwyg";
import { default as editor, RichUtils } from "draft-js";
//import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withFormik } from "formik";
import { EditorState } from "draft-js";
import { RichEditorExample } from "./RichEditor";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import PromotionAdmin from "./Promotion/PromotionAdmin";
import * as spaListActions from "../../../actions/spaListActions";

// const onTab = e => {
//     const maxDepth = 4;
//     this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
// };

// const SpaList = props => {
class SpaList extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      imageHash: Date.now()
    };
  }

  componentDidMount() {
    let x = this.props.spaListActions.getInitialValsSpaList();
    //console.log("NEW_REDUX: ", x);
    // console.log("NEW_REDUX: ", this.props.spaList);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  formikEnhancer = withFormik({
    mapPropsToValues: props => ({
      editorState: new EditorState.createEmpty(),
      email: ""
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("That's not an email")
        .required("Required!")
    }),
    handleSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }, 1000);
    },
    displayName: "MyForm"
  });

  onChange = editorState => {
    this.props.onChange("editorState", editorState);
  };

  focus = () => editor.focus();

  handleKeyCommand = command => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  spinner = () => {
    return (
      <div
        className="spinner-border"
        role="status"
        style={{ marginLeft: "15px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  render() {
    const { editorState } = this.props;
    // console.log("NEW_REDUX: ", this.props.listOfSpas);
    
    return (
      <div className="container-fluid">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Spa Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Media Uploads
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Promotions
            </NavLink>
          </NavItem>
        </Nav>
        <br />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Formik
              enableReinitialize
              initialValues={this.props.initialValues}
              validationSchema={Yup.object().shape({
                telephoneNumber: Yup.string().required(
                  "Telephone number is required"
                ),
                cssLight: Yup.bool().required("Telephone number is required"),
                facebookPageUrl: Yup.string().required(
                  "Facebook Page Url is required"
                ),
                instagramPageUrl: Yup.string().required(
                  "Instagram Page Url is required"
                ),
                name: Yup.string().required("Spa name is required"),
                story: Yup.string().required("Story is required"),
                cellNumber: Yup.string().required("Cell number is required"),
                physicalAddress: Yup.string().required(
                  "Physical address is required"
                ),
                instagramUsername: Yup.string().required(
                  "Instagram Username is required"
                ),
                receptionEmailAddress: Yup.string()
                  .email("Email is invalid")
                  .required("Email is required")
              })}
              onSubmit={this.props.onSubmit}
              render={({
                errors,
                status,
                touched,
                isSubmitting,
                setFieldValue,
                values,
                handleBlur
              }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="receptionEmailAddress">Email</label>
                    <Field
                      name="receptionEmailAddress"
                      type="text"
                      className={
                        "form-control" +
                        (errors.receptionEmailAddress &&
                        touched.receptionEmailAddress
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="receptionEmailAddress"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  {values.cssLight === true ? (
                    <div className="form-group">
                      <label htmlFor="cssLight">cssLight</label>
                      <Field
                        name="cssLight"
                        type="checkbox"
                        checked="checked"
                        className={
                          "form-control" +
                          (errors.cssLight && touched.cssLight
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="cssLight"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label htmlFor="cssLight">cssLight</label>
                      <Field
                        name="cssLight"
                        type="checkbox"
                        className={
                          "form-control" +
                          (errors.cssLight && touched.cssLight
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="cssLight"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label htmlFor="telephoneNumber">Telephone Number</label>
                    <Field
                      name="telephoneNumber"
                      type="text"
                      className={
                        "form-control" +
                        (errors.telephoneNumber && touched.telephoneNumber
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="telephoneNumber"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cellNumber">Cell Number</label>
                    <Field
                      name="cellNumber"
                      type="text"
                      className={
                        "form-control" +
                        (errors.cellNumber && touched.cellNumber
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="cellNumber"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="story">Story</label>
                    <Field
                      name="story"
                      type="text"
                      className={
                        "form-control" +
                        (errors.story && touched.story ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="story"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Spa Name</label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="physicalAddress">Physical Address</label>
                    <Field
                      name="physicalAddress"
                      type="text"
                      className={
                        "form-control" +
                        (errors.physicalAddress && touched.physicalAddress
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="physicalAddress"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="hotelUrl">Hotel Url</label>
                    <Field
                      name="hotelUrl"
                      type="text"
                      className={
                        "form-control" +
                        (errors.hotelUrl && touched.hotelUrl
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="hotelUrl"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="facebookPageUrl">facebookPageUrl</label>
                    <Field
                      name="facebookPageUrl"
                      type="text"
                      className={
                        "form-control" +
                        (errors.facebookPageUrl && touched.facebookPageUrl
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="facebookPageUrl"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="instagramPageUrl">Instagram Page Url</label>
                    <Field
                      name="instagramPageUrl"
                      type="text"
                      className={
                        "form-control" +
                        (errors.instagramPageUrl && touched.instagramPageUrl
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="instagramPageUrl"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="instagramUsername">
                      Instagram Username
                    </label>
                    <Field
                      name="instagramUsername"
                      type="text"
                      className={
                        "form-control" +
                        (errors.instagramUsername && touched.instagramUsername
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="instagramUsername"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label>Terms and Conditions</label>
                    <RichEditorExample
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      initialVal={this.props.editorStateInitial}
                    />
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col text-left">
                        <button
                          className="btn btn-secondary"
                          onClick={() => this.props.back()}
                        >
                          Back to list
                        </button>
                      </div>
                      <div className="text-right">
                        <button
                          type="submit"
                          className="btn btn-primary mr-2"
                          disabled={isSubmitting}
                        >
                          Submit
                          {isSubmitting ? this.spinner() : ""}
                        </button>
                        <button type="reset" className="btn btn-secondary">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
          </TabPane>
          <TabPane tabId="2">
            {/* {console.log("SPALIST: ", this.props)} */}
            {/* {console.log(this.props.CoverUrl)} */}
           
          </TabPane>
          <TabPane tabId="3">
            <PromotionAdmin
              Id={this.props.ID}
              back={this.props.back}
              name={this.props.initialValues.name}
              
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

//export default SpaList;

function mapStateToProps(state) {
  // console.log("SPALIST_FOR_NULL_CHECK: ", state.spaListReducer.spaList);
  return {
    listOfSpas: state.spaListReducer.spaList
  };
}

function mapDispatchToProps(dispatch) {
  // console.log(spaListActions);
  // console.log(dispatch);
  return {
    spaListActions: bindActionCreators(spaListActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaList);
